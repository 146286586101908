import React, { useState, useEffect, useCallback } from 'react';
/* Firebase */
import { db } from '../../firebase';
import { collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc, addDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
/* Icons */
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiPlusCircle } from "react-icons/fi";
import { FaSpinner } from 'react-icons/fa';
/* Components */
import Spinner from '../../components/Privacy/Spinner';
import Sidebar from '../../components/Nav/Sidebar';
import { toast } from 'react-toastify';
import moment from "moment";
/* Styles */
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function PromoCodes() {
    const [loadingButton, setLoadingButton] = useState(false);
    const [loading, setLoading] = useState(true);

    /* Get Promo Codes List */
    const [promoCodes, setPromoCodes] = useState([{
        promoID: '',
        promoName: '',
        promoCode: '', 
        promoCodeType: '', 
        discount: '', 
        promoCodeRemarks: '',
        promoDateStart: '',
        promoDateEnd: '',
        stripePromoID: '',
    }]);
    const pcHandleAddField = useCallback(() => {
        const newFields = [...promoCodes, { 
            promoID: '',
            promoName: '',
            promoCode: '', 
            promoCodeType: '', 
            discount: '', 
            promoCodeRemarks: '',
            promoDateStart: '',
            promoDateEnd: '',
            stripePromoID: '',
        }];
        setPromoCodes(newFields);
    }, [promoCodes]);

    const fetchPromoCodes = useCallback(async() => {
        const promoCodesRef = collection(db, "promoCodes");
        const q = query(
            promoCodesRef,
            orderBy("timestamp", "asc")
        );
        const querySnapshot = await getDocs(q);
        let additionalVariablesData = [];
        if(querySnapshot.docs.length > 0){
            querySnapshot.forEach((doc) => {
                const _de = doc.data().promoDateEnd;
                const dateEnd = moment(_de.toDate()).format('yy-MM-DD');
                return additionalVariablesData.push({
                    promoID: doc.id,
                    promoName: doc.data().promoName,
                    promoCode: doc.data().promoCode, 
                    promoCodeType: doc.data().promoCodeType, 
                    discount: doc.data().discount, 
                    promoCodeRemarks: doc.data().promoCodeRemarks,
                    promoDateEnd: dateEnd,
                    stripePromoID: doc.data().stripePromoID,
                })
            });
            setPromoCodes(additionalVariablesData);
        }
        setLoadingButton(false);
        setLoading(false);
    }, []);

    const pcHandleFieldChange = (index, event) => {
        const newFields = [...promoCodes];
        newFields[index][event.target.name] = event.target.value;
        setPromoCodes(newFields);
    };

    const pcHandleRemoveField = async (index, promoID) => {
        const newFields = [...promoCodes];
        console.log(newFields.length)
        if(promoID){
            if(window.confirm('Are you sure you want to delete this field?')){
                await deleteDoc(doc(db, "promoCodes", promoID));
                toast.success("Field deleted from database successfully.");
                if(newFields.length > 1){
                    newFields.splice(index, 1);
                    setPromoCodes(newFields);
                }
            }
        }else{
            if(newFields.length > 1){
                newFields.splice(index, 1);
                setPromoCodes(newFields);
            }
        }
    };

    async function submitPromoCodes(){
        setLoadingButton(true);
        // Save fields to Database
        promoCodes.map(async (field, index) => {
            const _dateEnd = new Date(promoCodes[index].promoDateEnd);
            const dateEnd = Timestamp.fromDate(_dateEnd);
            const formData = {
                promoName: promoCodes[index].promoName,
                promoCode: promoCodes[index].promoCode, 
                promoCodeType: promoCodes[index].promoCodeType, 
                discount: promoCodes[index].discount, 
                promoCodeRemarks: promoCodes[index].promoCodeRemarks,
                stripePromoID: promoCodes[index].stripePromoID,
                promoDateEnd: dateEnd,
                timestamp: serverTimestamp(),
            };
            if(field.promoID){
                const myCollection = doc(db, "promoCodes", field.promoID);
                await updateDoc(myCollection, formData);
            }else{
                const myCollection = collection(db, "promoCodes");
                await addDoc(myCollection, formData);
            }
        });
        fetchPromoCodes();
        toast.success("Fields saved successfully.");
    };

    useEffect(()=>{
        setLoading(true);
        fetchPromoCodes();
    }, [fetchPromoCodes]);

    /* Loading Screen */
    if(loading){
        return <Spinner />;
    }

    return (
        <>
            {/* Sidebar */}
            <Sidebar /> 

            {/* Section Content */}
            <section className="home_content">
                {/* Promo Codes Table */}
                <div className="dashboard-header-section">
                    <h4 className="page-title">Promo Codes</h4>
                </div>
                <div className="section-table">
                    <form>
                        <table className="form-table" id="promo-codes-table">
                            <thead>
                                <tr>
                                    <th>Expiration Date</th>
                                    <th>Promo Name</th>
                                    <th>Promo Code</th>
                                    <th>Type</th>
                                    <th>Discount</th>
                                    <th>Promo Code ID</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {promoCodes.map((field, index) => (
                                    <tr key={index}>
                                        <td className="tbl-long-field">
                                            <label className="field-label" htmlFor="">Expiration Date</label>
                                            <div className="date-fields">
                                                <input type="date" className="date-input" name="promoDateEnd" value={field.promoDateEnd} onChange={(e) => pcHandleFieldChange(index, e)} />
                                            </div>
                                        </td>
                                        <td className="tbl-long-field">
                                            <label className="field-label" htmlFor="">Promo Name</label>
                                            <input type="text" className="table-input" name="promoName" placeholder="Promo Name" value={field.promoName} onChange={(e) => pcHandleFieldChange(index, e)} />
                                        </td>
                                        <td className="tbl-long-field">
                                            <label className="field-label" htmlFor="">Promo Code</label>
                                            <input type="text" className="table-input" name="promoCode" placeholder="Promo Code" value={field.promoCode} onChange={(e) => pcHandleFieldChange(index, e)} />
                                        </td>
                                        <td className="tbl-type-field">
                                            <label className="field-label" htmlFor="">Type</label>
                                            <select name="promoCodeType" className="table-input" value={field.promoCodeType} onChange={(e) => pcHandleFieldChange(index, e)}>
                                                <option value="Percentage">Percentage</option>
                                                <option value="Fixed">Fixed</option>
                                            </select>
                                        </td>
                                        <td className="tbl-price-field">
                                            <label className="field-label" htmlFor="">Discount</label>
                                            <div className="form-input-with-price">
                                                <span className="sign">{(field.promoCodeType === "Fixed") ? "$" : "%"}</span>
                                                <input type="number" className="table-input" name="discount" min={0} max={100} placeholder="0.00" value={field.discount} onChange={(e) => pcHandleFieldChange(index, e)} />
                                            </div>
                                        </td>
                                        <td className="tbl-price-field">
                                            <label className="field-label" htmlFor="">Promo Code ID</label>
                                            <input type="text" className="table-input" name="stripePromoID" placeholder="Stripe Code ID	" value={field.stripePromoID} onChange={(e) => pcHandleFieldChange(index, e)} />
                                        </td>
                                        <td className="tbl-long-field">
                                        <label className="field-label" htmlFor="">Remarks</label>
                                            <div className="form-input-with-close">
                                                <input type="text" className="table-input" name="promoCodeRemarks" placeholder="Remarks" value={field.promoCodeRemarks} onChange={(e) => pcHandleFieldChange(index, e)} />
                                                <span className="action-btns" onClick={() => pcHandleRemoveField(index, field.promoID)}><AiOutlineCloseCircle /></span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="add-new-row" onClick={pcHandleAddField}><FiPlusCircle /></button>
                    </form>
                </div>
                <div className="s-btn-group">
                    {loadingButton ? 
                        (<button className="submit-button mt-50" type="button"><FaSpinner className="spinner" disabled /> Saving... </button>) : 
                        (<button className="submit-button mt-50" type="button" onClick={() => submitPromoCodes()}> Save</button>)
                    }
                </div>
            </section>
        </>
    )
}