import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import Spinner from '../../components/Privacy/Spinner';
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    function onChange(e){
        setEmail(e.target.value);
    }
    async function onSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const auth = getAuth();
            await sendPasswordResetEmail(auth, email);
            toast.success("Email sent successfully.");
        } catch (error) {
            toast.error("Could not send password reset. Email doesn't exist.");
            console.log(error);
        }
        setLoading(false);
    }
    if(loading){
        return <Spinner />;
    }
    return (
        <section className="main-form-section">
            <div className="form-section-wrapper">
                <form onSubmit={onSubmit}>
                    <a href="https://trustpointefinancials.com/" target="_blank" rel="noopener noreferrer">
                        <div className="form-header"></div>
                    </a>

                    {/* Email */}
                    <div className="form-wrapper">
                        <label htmlFor="email">Email Address *</label>
                        <div className="form-group">
                            <input type="email" className="form-input" id="email" name="email" placeholder="Email Address" value={email} onChange={onChange} required />
                        </div>
                    </div>
                    <div className="s-btn-group">
                        <button className="submit-button" id="submit-button" type="submit" style={{width: `100%`}}> Send Password Recovery</button>
                    </div>
                    <div className="sign-in-text">
                        <ul>
                            <li><Link to="/sign-in">Sign In Instead</Link></li>
                        </ul>
                    </div>
                </form>
            </div>
        </section>
    )
}
