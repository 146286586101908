import React, { useState, useEffect, useCallback } from 'react';
/* Firebase */
import { db } from '../../firebase';
import { collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc, addDoc, where, serverTimestamp } from 'firebase/firestore';
/* Icons */
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiPlusCircle } from "react-icons/fi";
import { FaSpinner } from 'react-icons/fa';
/* Components */
import Spinner from '../../components/Privacy/Spinner';
import Sidebar from '../../components/Nav/Sidebar';
import { toast } from 'react-toastify';
/* Styles */
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function AddNewEstimates({ categoryName }) {
    const [loadingButton, setLoadingButton] = useState(false);
    const [loading, setLoading] = useState(true);
    const defaultFormCategory = categoryName;

    /* =============================================== Business Estimates ======================================================= */
    /* Get Estimation List */
    const [estimationList, setEstimationList] = useState([]);

    const elHandleAddField = useCallback(() => {
        const newFields = [...estimationList, { 
            formID: '', 
            formType: '', 
            formDescription: '', 
            formTypePrice: '', 
            formPrice: '', 
            remarks: '', 
            stripePriceID: '',
            order: estimationList.length + 1,
            formCategory: defaultFormCategory
        }];
        setEstimationList(newFields);
    }, [estimationList, defaultFormCategory]);

    const fetchEstimationList = useCallback(async() => {
        const estimationListRef = collection(db, "estimationList");
        const q = query(
            estimationListRef,
            where("formCategory", "==", defaultFormCategory),
            orderBy("order", "asc")
        );
        const querySnapshot = await getDocs(q);
        let estimationListData = [];
        if(querySnapshot.docs.length > 0){
            var qsIndex = 0;
            querySnapshot.forEach((doc) => {
                qsIndex += 1;
                return estimationListData.push({
                    ...doc.data(),
                    formID: doc.id,
                    order: qsIndex,
                });
            });
            setEstimationList(estimationListData);
        }
        setLoadingButton(false);
        setLoading(false);
    }, [defaultFormCategory]);

    const elHandleFieldChange = (index, event) => {
        const newFields = [...estimationList];
        newFields[index][event.target.name] = event.target.value;
        setEstimationList(newFields);
    };

    const elHandleRemoveField = async (index, formID) => {
        const newFields = [...estimationList];
        if(formID){
            if(window.confirm('Are you sure you want to delete this field?')){
                await deleteDoc(doc(db, "estimationList", formID));
                toast.success("Field deleted from database successfully.");
                if(newFields.length > 1){
                    let newEstimationListData = [];
                    var qsIndex = 0;
                    newFields.splice(index, 1);
                    newFields.forEach((item) => {
                        qsIndex += 1;
                        return newEstimationListData.push({
                            ...item,
                            order: qsIndex,
                        });
                    });
                    setEstimationList(newEstimationListData);
                }
            }
        }else{
            if(newFields.length > 1){
                let newEstimationListData = [];
                var qsIndex = 0;
                newFields.splice(index, 1);
                newFields.forEach((item) => {
                    qsIndex += 1;
                    return newEstimationListData.push({
                        ...item,
                        order: qsIndex,
                    });
                });
                setEstimationList(newEstimationListData);
            }
        }
    };

    /* Submit Estimation List */
    async function submitEstimationList(){
        setLoadingButton(true);
        // Save fields to Database
        estimationList.map(async (field, index) => {
            const formData = {
                ...estimationList[index],
                timestamp: serverTimestamp(),
            };
            delete formData.formID;
            if(field.formID){
                const myCollection = doc(db, "estimationList", field.formID);
                await updateDoc(myCollection, formData);
            }else{
                const myCollection = collection(db, "estimationList");
                await addDoc(myCollection, formData);
            }
        });
        fetchEstimationList();
        submitAdditionalVariables();
    };

    /* =============================================== ADDITIONAL VARIABLES ======================================================= */
    /* Get Advance List */
    const [additionalVariables, setAdditionalVariables] = useState([{
        avID: '', 
        fieldTitle: '', 
        fieldType: '',
        fieldValue: '',
        fieldRemarks: '',
        formCategory: defaultFormCategory
    }]);
    const avHandleAddField = useCallback(() => {
        const newFields = [...additionalVariables, { 
            avID: '', 
            fieldTitle: '', 
            fieldType: '',
            fieldValue: '',
            fieldRemarks: '',
            formCategory: defaultFormCategory}
        ];
        setAdditionalVariables(newFields);
    }, [additionalVariables, defaultFormCategory]);

    const fetchAdditionalVariables = useCallback(async() => {
        const additionalVariablesRef = collection(db, "additionalVariables");
        const q = query(
            additionalVariablesRef,
            where("formCategory", "==", defaultFormCategory),
            orderBy("timestamp", "asc")
        );
        const querySnapshot = await getDocs(q);
        let additionalVariablesData = [];
        if(querySnapshot.docs.length > 0){
            querySnapshot.forEach((doc) => {
                return additionalVariablesData.push({
                    ...doc.data(),
                    avID: doc.id
                })
            });
            setAdditionalVariables(additionalVariablesData);
        }
        setLoadingButton(false);
        setLoading(false);
    }, [defaultFormCategory]);

    const avHandleFieldChange = (index, event) => {
        const newFields = [...additionalVariables];
        newFields[index][event.target.name] = event.target.value;
        setAdditionalVariables(newFields);
    };

    const avHandleRemoveField = async (index, avID) => {
        const newFields = [...additionalVariables];
        console.log(newFields.length)
        if(avID){
            if(window.confirm('Are you sure you want to delete this field?')){
                await deleteDoc(doc(db, "additionalVariables", avID));
                toast.success("Field deleted from database successfully.");
                if(newFields.length > 1){
                    newFields.splice(index, 1);
                    setAdditionalVariables(newFields);
                }
            }
        }else{
            if(newFields.length > 1){
                newFields.splice(index, 1);
                setAdditionalVariables(newFields);
            }
        }
    };

    async function submitAdditionalVariables(){
        setLoadingButton(true);
        // Save fields to Database
        additionalVariables.map(async (field, index) => {
            const formData = {
                ...additionalVariables[index],
                timestamp: serverTimestamp(),
            };
            delete formData.avID;
            if(field.avID){
                const myCollection = doc(db, "additionalVariables", field.avID);
                await updateDoc(myCollection, formData);
            }else{
                const myCollection = collection(db, "additionalVariables");
                await addDoc(myCollection, formData);
            }
        });
        fetchAdditionalVariables();
        toast.success("Fields saved successfully.");
    };

    useEffect(()=>{
        setLoading(true);
        fetchEstimationList();
        fetchAdditionalVariables();
    }, [fetchEstimationList, fetchAdditionalVariables]);

    /* COMMON FUNCTIONS */
    /* Loading Screen */
    if(loading){
        return <Spinner />;
    }
    /* CLOSE COMMON FUNCTIONS */

    return (
        <>
            {/* Sidebar */}
            <Sidebar /> 

            {/* Section Content */}
            <section className="home_content">
                {/* Individual Estimates Table */}
                <div className="dashboard-header-section">
                    <h4 className="page-title">{defaultFormCategory}</h4>
                </div>
                <div className="section-table">
                    <form onSubmit={submitEstimationList}>
                        <table className="form-table" id="estimation-list-table">
                            <thead>
                                <tr>
                                    <th>Form Type</th>
                                    <th>Form Description</th>
                                    <th>Type</th>
                                    <th>Price</th>
                                    <th>Stripe Price ID</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {estimationList.map((field, index) => (
                                    <tr key={index}>
                                        <td className="tbl-form-name-field">
                                            <label className="field-label" htmlFor="">Form Type</label>
                                            <input type="text" className="table-input" name="formType" placeholder="Form Name" value={field.formType} onChange={(e) => elHandleFieldChange(index, e)} />
                                        </td>
                                        <td className="tbl-long-field">
                                            <label className="field-label" htmlFor="">Form Description</label>
                                            <input type="text" className="table-input" name="formDescription" placeholder="Form Description" value={field.formDescription} onChange={(e) => elHandleFieldChange(index, e)} />
                                        </td>
                                        <td className="tbl-type-field">
                                            <label className="field-label" htmlFor="">Type</label>
                                            <select name="formTypePrice" className="table-input" value={field.formTypePrice} onChange={(e) => elHandleFieldChange(index, e)}>
                                                <option value="Fixed">Fixed</option>
                                                <option value="Quantity">Quantity</option>
                                                <option value="Checkbox">Checkbox</option>
                                            </select>
                                        </td>
                                        <td className="tbl-price-field">
                                            <label className="field-label" htmlFor="">Price</label>
                                            <div className="form-input-with-price">
                                                <span className="sign">$</span>
                                                <input type="number" className="table-input" name="formPrice" placeholder="0.00" min={0.1} value={field.formPrice} onChange={(e) => elHandleFieldChange(index, e)} />
                                            </div>
                                        </td>
                                        <td className="tbl-form-name-field">
                                            <label className="field-label" htmlFor="">Stripe Price ID</label>
                                            <input type="text" className="table-input" name="stripePriceID" placeholder="Stripe Price ID" value={field.stripePriceID} onChange={(e) => elHandleFieldChange(index, e)} />
                                        </td>
                                        <td className="tbl-long-field">
                                            <label className="field-label" htmlFor="">Remarks</label>
                                            <div className="form-input-with-close">
                                                <input type="text" className="table-input" name="remarks" placeholder="Remarks" value={field.remarks} onChange={(e) => elHandleFieldChange(index, e)} />
                                                <span className="action-btns" onClick={() => elHandleRemoveField(index, field.formID)}><AiOutlineCloseCircle /></span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="add-new-row" onClick={elHandleAddField}><FiPlusCircle /></button>
                    </form>
                </div>

                {/* Additional Variables Table */}
                <div className="dashboard-header-section">
                    <h4 className="page-title">Additional Variables</h4>
                </div>
                <div className="section-table">
                    <form>
                        <table className="form-table" id="additional-variables-table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Type</th>
                                    <th>Value</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {additionalVariables.map((field, index) => (
                                    <tr key={index}>
                                        <td className="tbl-long-field">
                                            <label className="field-label" htmlFor="">Title</label>
                                            <input type="text" className="table-input" name="fieldTitle" placeholder="Form Title" value={field.fieldTitle} onChange={(e) => avHandleFieldChange(index, e)} />
                                        </td>
                                        <td className="tbl-type-field">
                                            <label className="field-label" htmlFor="">Type</label>
                                            <select name="fieldType" className="table-input" value={field.fieldType} onChange={(e) => avHandleFieldChange(index, e)}>
                                                <option value="Percentage">Percentage</option>
                                                <option value="Fixed">Fixed</option>
                                            </select>
                                        </td>
                                        <td className="tbl-price-field">
                                            <label className="field-label" htmlFor="">Value</label>
                                            <div className="form-input-with-price">
                                                <span className="sign">{(field.fieldType === "Fixed") ? "$" : "%"}</span>
                                                <input type="number" className="table-input" name="fieldValue" min={0} max={100} placeholder="0.00" value={field.fieldValue} onChange={(e) => avHandleFieldChange(index, e)} />
                                            </div>
                                        </td>
                                        <td className="tbl-long-field">
                                            <label className="field-label" htmlFor="">Remarks</label>
                                            <div className="form-input-with-close">
                                                <input type="text" className="table-input" name="fieldRemarks" placeholder="Remarks" value={field.fieldRemarks} onChange={(e) => avHandleFieldChange(index, e)} />
                                                <span className="action-btns" onClick={() => avHandleRemoveField(index, field.avID)}><AiOutlineCloseCircle /></span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="add-new-row" onClick={avHandleAddField}><FiPlusCircle /></button>
                    </form>
                </div>
                <div className="s-btn-group">
                    {loadingButton ? 
                        (<button className="submit-button mt-50" type="button"><FaSpinner className="spinner" disabled /> Saving... </button>) : 
                        (<button className="submit-button mt-50" type="button" onClick={() => submitEstimationList()}> Save</button>)
                    }
                </div>
            </section>
        </>
    )
}