import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './pages/Auth/SignUp';
import SignIn from './pages/Auth/SignIn';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Dashboard from './pages/Features/Dashboard';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './components/Privacy/PrivateRoute';
import SignUpRoute from './components/Privacy/SignUpRoute';
import IndividualEstimates from './pages/Features/IndividualEstimates';
import BusinessEstimates from './pages/Features/BusinessEstimates';
import BookkeepingEstimates from './pages/Features/BookkeepingEstimates';
import OrganizeEstimates from './pages/Features/OrganizeEstimates';
import 'react-toastify/dist/ReactToastify.css';
import PromoCodes from './pages/Features/PromoCodes';
import SubmittedEstimates from './pages/Features/SubmittedEstimates';
import ViewEstimates from './pages/Features/ViewEstimates';
import SubmitEstimates from './pages/FrontPage/SubmitEstimates';
import SumitEstimatesExample from './pages/FrontPage/SumitEstimatesExample';
import ThankYou from './pages/FrontPage/ThankYou';

function App() {
    return (
        <>
            <Router>
                <Routes>
                    {/* =================== Auth ===================== */}
                    {/* Sign In */}
                    <Route path="/" element={<SignUpRoute />}>
                        <Route path="/" element={<SignIn />} />
                    </Route>
                    <Route path="/sign-in" element={<SignUpRoute />}>
                        <Route path="/sign-in" element={<SignIn />} />
                    </Route>
                    {/* Sign Up */}
                    <Route path="/sign-up" element={<SignUpRoute />}>
                        <Route path="/sign-up" element={<SignUp />} />
                    </Route>
                    {/* Forgot Password */}
                    <Route path="/forgot-password" element={<SignUpRoute />}>
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                    </Route>

                    {/* =================== Features ===================== */}
                    <Route path="/dashboard" element={<PrivateRoute />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                    </Route>
                    <Route path="/individual-estimates-calculator" element={<PrivateRoute />}>
                        <Route path="/individual-estimates-calculator" element={<IndividualEstimates />} />
                    </Route>
                    <Route path="/business-estimates-calculator" element={<PrivateRoute />}>
                        <Route path="/business-estimates-calculator" element={<BusinessEstimates />} />
                    </Route>
                    <Route path="/bookkeeping-estimates-calculator" element={<PrivateRoute />}>
                        <Route path="/bookkeeping-estimates-calculator" element={<BookkeepingEstimates />} />
                    </Route>
                    <Route path="/promo-codes" element={<PrivateRoute />}>
                        <Route path="/promo-codes" element={<PromoCodes />} />
                    </Route>
                    <Route path="/submitted-estimates" element={<PrivateRoute />}>
                        <Route path="/submitted-estimates" element={<SubmittedEstimates />} />
                    </Route>
                    <Route path="/view-estimates" element={<PrivateRoute />}>
                        <Route path="/view-estimates/:clientID" element={<ViewEstimates />} />
                    </Route>
                    <Route path="/organize-estimates" element={<PrivateRoute />}>
                        <Route path="/organize-estimates" element={<OrganizeEstimates />} />
                    </Route>

                    {/* =================== Front Page ===================== */}
                    <Route path="/submit-estimates" element={<SubmitEstimates />} />
                    <Route path="/submit-example" element={<SumitEstimatesExample />} />
                    <Route path="/thank-you-page" element={<ThankYou />} />

                </Routes>
            </Router>
            
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    );
}

export default App;
