import React, { useState } from 'react';
import Stripe from 'stripe';
import config from '../../config';
import axios from 'axios';
import { v1 as uuidv4 } from "uuid";
/* Styles */
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function SumitEstimatesExample() {
    const stripe = new Stripe(config.STRIPE_API_KEY);
    const [quote, setQuote] = useState(null);
    async function createQuote() {
        try {
            // Create Customers
            const customer = await stripe.customers.create({
                name: 'John Doe7',
                email: 'john.doe7@example.com',
                phone: '+1 123-456-7890',
                address: {
                    line1: '123 Main St',
                    line2: 'Apt 1',
                    city: 'Anytown',
                    state: 'CA',
                    postal_code: '12345',
                    country: 'US',
                },
            });
            // Create Quote
            if(customer){
                try {
                    const expiresAt = Math.floor((Date.now() / 1000) + (15 * 24 * 60 * 60)); // 15 days from now
                    const quote = await stripe.quotes.create({
                        customer: customer.id,
                        header: 'Tax Estimates Quote',
                        expires_at: expiresAt,
                        line_items: [
                            {
                                price: 'price_1N3x6vIhHDtglTuW1CNTShfI',
                                quantity: 2,
                            },
                            {
                                price: 'price_1N3QSgIhHDtglTuW5yBPkrVz',
                                quantity: 3,
                            },
                        ]
                    });
                    console.log(quote);

                    // Finalize Quote and Get the PDF
                    if(quote){
                        const quoteFinalize = await stripe.quotes.finalizeQuote(quote.id);
                        if(quoteFinalize){
                            downloadQuotePdf(quote.id);
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
            console.log(quote);
        } catch (error) {
            console.error(error);
        }
    }

    async function downloadQuotePdf(quoteId) {
        try {
            const fileName = `New Quote Estimate - ${uuidv4()}.pdf`
            const response = await axios.get(`https://files.stripe.com/v1/quotes/${quoteId}/pdf`, {
                headers: {
                    'Content-Type': 'application/pdf',
                    'Authorization': `Bearer ${config.STRIPE_API_KEY}`,
                    'Content-disposition': 'attachment; filename=" + "'+fileName
                },
                responseType: 'arraybuffer'
            });

            const data = response.data;

            // create a download anchor tag
            var downloadLink      = document.createElement('a');
            downloadLink.target   = '_blank';
            downloadLink.download = fileName;

            // convert downloaded data to a Blob
            var blob = new Blob([data], { type: 'application/pdf' });

            // create an object URL from the Blob
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            // set object URL as the anchor's href
            downloadLink.href = downloadUrl;

            // append the anchor to document body
            document.body.append(downloadLink);

            // fire a click event on the anchor
            downloadLink.click();

            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
            
        } catch (error) {
          console.error(error);
        }
    }
    return (
        <>
            <button onClick={createQuote}>Create Quote</button>
            <button onClick={downloadQuotePdf}>Download PDF Quote</button>
        </>
    );
}
