import React from 'react';
import { MdLogout } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';
import { toast } from 'react-toastify';
import companyLogo from "../../assets/img/company-logo.png";
import mobileLogo from "../../assets/img/logo512.png"
import { useState } from 'react';


export default function Sidebar() {
    const auth = getAuth();
    const location = useLocation();
    const navigate = useNavigate();
    function pathRoutes(route){
        if(route === location.pathname){
            return 'active';
        }
    }
    function signOut(e){
        e.preventDefault();
        auth.signOut();
        toast.success("Successfully logout. Thank you!");
        navigate("/");
    }
    const [isClass1Clicked, setIsClass1Clicked] = useState(false);
    const [isClass2Clicked, setIsClass2Clicked] = useState(false);

    const handleClass1Click = () => {
        setIsClass1Clicked(true);
        setIsClass2Clicked(false);
    };

    const handleClass2Click = () => {
        setIsClass2Clicked(true);
        setIsClass1Clicked(false);
    };

    return (
        <aside className= {isClass1Clicked ? 'sidebar open-menu' : 'sidebar close-menu' || isClass2Clicked ? 'sidebar close-menu' : 'sidebar close-menu' }>
            <button type="button"  onClick={handleClass1Click} 
                className={isClass1Clicked ? 'backmenu-icon hide' : 'backmenu-icon'}>
                <span className="sr-only">Open menu</span>
                <svg className="pointer-events-none w-6" id="6448950f6bd80" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                </svg>
            </button>
            <button type="button"  onClick={handleClass2Click} className={isClass2Clicked ? 'backmenu-icon close hide' : 'backmenu-icon close'} >
                <span className="sr-only">Close menu</span>
                <svg className="pointer-events-none w-6" id="6448950f8e7e1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </button>
            <ul className="nav_list">
                <li className="comp-logo-sidebar">
                    <a className="comp-logo-link" href="https://trustpointefinancials.com/" target="_blank" rel="noopener noreferrer">
                        <img src={companyLogo} alt="Company Logo" />
                    </a>
                </li>
                <li className="comp-logo-sidebar mobile">
                    <a className="comp-logo-link" href="https://trustpointefinancials.com/" target="_blank" rel="noopener noreferrer">
                        <img src={mobileLogo} alt="Company Logo" />
                    </a>
                </li>
                <li>
                    <Link to="/dashboard" className={pathRoutes('/dashboard') || ''}>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link to="/individual-estimates-calculator" className={pathRoutes('/individual-estimates-calculator') || ''}>
                        <span>Individual Estimates Calculator</span>
                    </Link>
                </li>
                <li>
                    <Link to="/business-estimates-calculator" className={pathRoutes('/business-estimates-calculator') || ''}>
                        <span>Business Estimates Calculator</span>
                    </Link>
                </li>
                <li>
                    <Link to="/bookkeeping-estimates-calculator" className={pathRoutes('/bookkeeping-estimates-calculator') || ''}>
                        <span>Bookkeeping Estimates Calculator</span>
                    </Link>
                </li>
                <li>
                    <Link to="/organize-estimates" className={pathRoutes('/organize-estimates') || ''}>
                        <span>Organize Estimates Order</span>
                    </Link>
                </li>
                <li>
                    <Link to="/promo-codes" className={pathRoutes('/promo-codes') || ''}>
                        <span>Promo Codes</span>
                    </Link>
                </li>
                <li>
                    <Link to="/submitted-estimates" className={pathRoutes('/submitted-estimates') || pathRoutes('/view-estimates') || ''}>
                        <span>Submitted Estimates</span>
                    </Link>
                </li>
                
                <li className="logout-nav">
                    <Link onClick={signOut}>
                        <i className="bx"><MdLogout /></i> <span>Logout</span>
                    </Link>
                </li>
            </ul>
        </aside>
    )
}
