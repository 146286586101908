import React, { useState, useEffect, useCallback } from 'react';
/* Firebase */
import { db } from '../../firebase';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import moment from "moment";
/* Icons */
import { CgPlayListRemove, CgPlayListCheck, CgPlayListAdd } from "react-icons/cg";
import { RiCoupon2Line }  from "react-icons/ri";
import { HiOutlineClipboardList }  from "react-icons/hi";
/* Components */
import Sidebar from '../../components/Nav/Sidebar';
import Spinner from '../../components/Privacy/Spinner';
/* Styles */
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function Dashboard() {
    const [loading, setLoading] = useState(true);

    /* Submission Summary */
    const [cntEstimationList, setCntEstimationList] = useState({
        bookkeepingEst: 0,
        businessEst: 0,
        individualEst: 0,
    });
    const [clientSubmissionsSummary, setClientSubmissionsSummary] = useState({
        pending: 0,
        canceled: 0,
        completed: 0,
    });
    const [latestTransations, setLatestTransations] = useState([]);
    const [coupons, setCoupons] = useState(0);

    // Define a function to search for objects by key and value
    const searchObjects = (arr, key, value) => {
        return arr.filter((obj) => {
            return obj[key] === value || (Array.isArray(obj[key]) && searchObjects(obj[key], key, value).length);
        });
    };

    useEffect(()=>{
        setLoading(true);
        async function fetchAllData(){
            //  Fetch All Estimation List
            let bookkeepingEst = 0;
            let businessEst = 0;
            let individualEst = 0;
            const estimatesRef = collection(db, "estimationList");
            const q = query(estimatesRef, orderBy("timestamp", "desc"));
            const querySnapshot = await getDocs(q);
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((_doc) => {
                    if(_doc.data().formCategory === "Bookkeeping Estimates"){
                        bookkeepingEst += 1;
                    }
                    if(_doc.data().formCategory === "Business Estimates"){
                        businessEst += 1;
                    }
                    if(_doc.data().formCategory === "Individual Estimates"){
                        individualEst += 1;
                    }
                });
            }
            setCntEstimationList({
                bookkeepingEst: bookkeepingEst,
                businessEst: businessEst,
                individualEst: individualEst,
            });
        }

        async function fetchClientsTotalComputation(){
            //  Fetch All Clients
            const clientsRef = collection(db, "clients");
            const q2 = query(clientsRef, orderBy("timestamp", "desc"));
            const querySnapshot2 = await getDocs(q2);
            let clientsData = [];
            if(querySnapshot2.docs.length > 0){
                querySnapshot2.forEach((_doc2) => {
                    return clientsData.push({
                        ..._doc2.data(),
                        clientID: _doc2.id,
                    });
                });
            }

            // Fetch Client's Total Computation
            const clientsTotalComputationRef = collection(db, "clientsTotalComputation");
            const q = query(clientsTotalComputationRef, orderBy("timestamp", "desc"));
            const querySnapshot = await getDocs(q);
            let clientsTotalComputationData = [];
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((_doc) => {
                    const results = searchObjects(clientsData, "clientID", _doc.data().clientID);
                    const _dateSubmitted = _doc.data().timestamp;
                    const dateSubmitted = moment(_dateSubmitted.toDate()).format('MM/DD/yy');
                    const _dateExpire = _doc.data().expiresOn;
                    const dateExpire = moment(_dateExpire.toDate()).format('MM/DD/yy');
                    clientsTotalComputationData.push({
                        ..._doc.data(),
                        dateSubmitted: dateSubmitted,
                        dateExpire: dateExpire,
                        clientName: (results[0]) ? results[0].fullName: '',
                        totalPrice: `$ ${Number(_doc.data().totalEstimatePrice).toFixed(2)}`,
                        ...results[0]
                    });
                });
            }
            const latestTransacData = clientsTotalComputationData.slice(0, 10);
            setLatestTransations(latestTransacData);

            //  Fetch All Estimation List
            let cPending = 0;
            let cCanceled = 0;
            let cCompleted = 0;
            
            clientsTotalComputationData.forEach((item) => {
                if(item.status === "Pending"){
                    cPending += 1;
                }
                if(item.status === "Canceled"){
                    cCanceled += 1;
                }
                if(item.status === "Completed"){
                    cCompleted += 1;
                }
            });
            setClientSubmissionsSummary({
                pending: cPending,
                canceled: cCanceled,
                completed: cCompleted,
            });
            setLoading(false);
        }

        async function fetchCoupons(){
            //  Fetch All Estimation List
            const estimatesRef = collection(db, "promoCodes");
            const q = query(estimatesRef, orderBy("timestamp", "desc"));
            const querySnapshot = await getDocs(q);
            setCoupons(querySnapshot.docs.length);
        }

        fetchClientsTotalComputation();
        fetchAllData();
        fetchCoupons();
    }, []);

    /* Loading Screen */
    if(loading){
        return <Spinner />;
    }

    return (
        <>
            {/* Sidebar */}
            <Sidebar /> 

            {/* Section Content */}
            <section className="home_content">
                {/* Section Header */}
                <div className="dashboard-header-section">
                    <h4 className="page-title">Dashboard</h4>
                </div>
                
                {/* Submission Summary */}
                <div className="dashboard-header-section">
                    <h4 className="page-title-sm">Submission Summary</h4>
                </div>
                <div className="widget-wrapper">
                    <div className="widget-bg-color-icon card-box x3-box">
                        <div className="bg-icon bg-icon-info">
                            <CgPlayListAdd />
                        </div>
                        <div className="text-right">
                            <h3 className="text-dark"><b className="counter">{ clientSubmissionsSummary.pending }</b></h3>
                            <p className="text-muted">Pending</p>
                        </div>
                    </div>
                    <div className="widget-bg-color-icon card-box x3-box">
                        <div className="bg-icon bg-icon-danger">
                            <CgPlayListRemove />
                        </div>
                        <div className="text-right">
                            <h3 className="text-dark"><b className="counter">{ clientSubmissionsSummary.canceled }</b></h3>
                            <p className="text-muted">Canceled</p>
                        </div>
                    </div>
                    <div className="widget-bg-color-icon card-box x3-box">
                        <div className="bg-icon bg-icon-success">
                            <CgPlayListCheck />
                        </div>
                        <div className="text-right">
                            <h3 className="text-dark"><b className="counter">{ clientSubmissionsSummary.completed }</b></h3>
                            <p className="text-muted">Completed</p>
                        </div>
                    </div>
                </div>

                {/* Total Form Summary */}
                <div className="dashboard-header-section">
                    <h4 className="page-title-sm">Total Form Summary</h4>
                </div>
                <div className="widget-wrapper">
                    <div className="widget-bg-color-icon card-box x3-box">
                        <div className="bg-icon bg-icon-purple">
                            <HiOutlineClipboardList />
                        </div>
                        <div className="text-right">
                            <h3 className="text-dark"><b className="counter">{ cntEstimationList.individualEst }</b></h3>
                            <p className="text-muted">Individual Estimates</p>
                        </div>
                    </div>
                    <div className="widget-bg-color-icon card-box x3-box">
                        <div className="bg-icon bg-icon-purple">
                            <HiOutlineClipboardList />
                        </div>
                        <div className="text-right">
                            <h3 className="text-dark"><b className="counter">{ cntEstimationList.businessEst }</b></h3>
                            <p className="text-muted">Business Estimates</p>
                        </div>
                    </div>
                    <div className="widget-bg-color-icon card-box x3-box">
                        <div className="bg-icon bg-icon-purple">
                            <HiOutlineClipboardList />
                        </div>
                        <div className="text-right">
                            <h3 className="text-dark"><b className="counter">{ cntEstimationList.bookkeepingEst }</b></h3>
                            <p className="text-muted">Bookkeeping Estimates</p>
                        </div>
                    </div>
                </div>

                 {/* Latest Transactions */}
                <div className="dashboard-header-section">
                    <h4 className="page-title-sm">Latest Transactions</h4>
                </div>
                <div className="section-table">
                    <table className="form-table">
                        <thead>
                            <tr>
                                <th>Date Submitted</th>
                                <th>Date of Expiry</th>
                                <th>Client Name</th>
                                <th>Contact Email</th>
                                <th>Status</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {latestTransations.length ? (
                                latestTransations.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Date Submitted:</label>
                                                <span>{ item.dateSubmitted }</span>
                                            </td>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Date of Expiry:</label>
                                                <span>{ item.dateExpire }</span>
                                            </td>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Client Name:</label>
                                                <span>{ item.clientName }</span>
                                            </td>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Contact Email:</label>
                                                <span>{ item.email }</span>
                                            </td>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Status:</label>
                                                <span className={"tbl-status status-"+item.status}>{ item.status }</span>
                                            </td>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Total Price:</label>
                                                <span>{ item.totalPrice }</span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td className="tbl-form-field" colSpan={6}>
                                        <label className="field-label">Empty:</label>
                                        <span>No records found.</span>
                                    </td>
                                </tr>
                            )}


                        </tbody>
                    </table>
                    <div className="promocodes-widget">
                        <span className="promo-cicon"><RiCoupon2Line /></span>
                        <p>Total Coupons Added</p>
                        <h4>{ coupons }</h4>
                    </div>
                </div>
            </section>

        </>
    )
}
