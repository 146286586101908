import React, { useState, useEffect } from 'react';
import Nestable from 'react-nestable';
/* Firebase */
import { db } from '../../firebase';
import { collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc, addDoc, where, serverTimestamp } from 'firebase/firestore';
/* Icons */
import { FaSpinner } from 'react-icons/fa';
import { FaRegSave } from 'react-icons/fa';
import { MdDragIndicator } from 'react-icons/md';
/* Components */
import Spinner from '../../components/Privacy/Spinner';
import Sidebar from '../../components/Nav/Sidebar';
import { toast } from 'react-toastify';
/* Styles */
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';
import 'react-nestable/dist/styles/index.css';

export default function OrganizeEstimates() {
    const [loadingButton, setLoadingButton] = useState(false);
    const [defaultFormCategory, setDefaultFormCategory] = useState('Individual Estimates');
    const [loading, setLoading] = useState(true);
    const [estimationList, setEstimationList] = useState([]);

    useEffect(()=>{
        setLoading(true);
        async function fetchEstimationList(){
            const estimationListRef = collection(db, "estimationList");
            const q = query(
                estimationListRef,
                where("formCategory", "==", defaultFormCategory),
                orderBy("order", "asc")
            );
            const querySnapshot = await getDocs(q);
            let estimationListData = [];
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((doc) => {
                    return estimationListData.push({
                        ...doc.data(),
                        formID: doc.id,
                        id: doc.id
                    })
                });
                setEstimationList(estimationListData);
            }
            setLoading(false);
        }
        fetchEstimationList();
    }, [defaultFormCategory]);

    const renderItem = ({ item }) => {
        // return `${item.formType} - ${item.formDescription}`
        const price = Number(item.formPrice);
        return (
            <>
                <span className="drag-icon"><MdDragIndicator /></span> <span className="drag-text">{item.formType} | {item.formDescription} - $ {price.toFixed(2)}</span>
            </>
        )
    };

    const onArrangeHandler = (result) =>{
        setEstimationList(result.items);
    };

    const saveEstimatesOrder = () =>{
        setLoadingButton(true);
        // Save fields to Database
        estimationList.map(async (field, index) => {
            const formData = {
                ...estimationList[index],
                order: index + 1,
            };
            delete formData.id;
            delete formData.children;
            const myCollection = doc(db, "estimationList", field.formID);
            await updateDoc(myCollection, formData);
        });
        setLoadingButton(false);
        toast.success("Changes saved successfully.");
    };

    const changeCategory = (event) => {
        setDefaultFormCategory(event.target.value);
    }

    /* Loading Screen */
    if(loading){
        return <Spinner />;
    }

    return (
        <>
            {/* Sidebar */}
            <Sidebar /> 

            {/* Section Content */}
            <section className="home_content">
                {/* Organized Items Table */}
                <div className="dashboard-header-section">
                    <h4 className="page-title">Organized Items for {defaultFormCategory}</h4>
                </div>
                <div className="search-estimates-wrapper w-100">
                    <div className="search-est-items w-100">
                        <select name="defaultFormCategory" className="search-input-field w-100" id="defaultFormCategory" value={defaultFormCategory} onChange={changeCategory}>
                            <option value="Individual Estimates">Individual Estimates</option>
                            <option value="Business Estimates">Business Estimates</option>
                            <option value="Bookkeeping Estimates">Bookkeeping Estimates</option>
                        </select>
                    </div>
                </div>
                <div className="section-table nestable-lists">
                    <h5>Drag and Drop items to rearrange them according to your order</h5>
                    <Nestable
                        items={estimationList}
                        renderItem={renderItem}
                        collapsed={false}
                        onChange={onArrangeHandler}
                        maxDepth={0}
                    />
                </div>
                <div className="help-button">
                    {loadingButton ? 
                        (<button className="float-help-btn" type="button"><FaSpinner className="spinner" disabled /> Saving... </button>) : 
                        (<button className="float-help-btn" type="button" onClick={() => saveEstimatesOrder()}><FaRegSave /> <span>Save</span></button>)
                    }
                </div>
            </section>
        </>
    )
}
