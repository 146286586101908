import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineExclamationCircle, AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../components/Privacy/Spinner';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { db } from '../../firebase';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function SignUp() {
    const [showPass, setShowPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [formData, setFormData] = useState({
        fullname: "",
        email: "",
        password: "",
    });
    const { fullname, email, password } = formData;
    const navigate = useNavigate();
    function onChange(e){
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    }
    async function onSubmit(e){
        e.preventDefault();
        setLoading(true);
        try {
            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            updateProfile(auth.currentUser, {
                displayName: fullname
            });
            const user = userCredential.user;
            // Save data to database
            const docRef = doc(db, "users", user.uid);
            await setDoc(docRef, {
                name: fullname,
                email: email,
                timestamp: serverTimestamp(),
            });
            setShowErrorMsg(false);
            setLoading(false);
            navigate('/');
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowErrorMsg(true);
        }
    }
    if(loading){
        return <Spinner />;
    }

    return (
        <section className="main-form-section">
            <div className="form-section-wrapper">
                <form onSubmit={onSubmit}>
                    <a href="https://trustpointefinancials.com/" target="_blank" rel="noreferrer">
                        <div className="form-header"></div>
                    </a>

                    {/* Show if error occured */}
                    {showErrorMsg ? (
                        <div className="alert alert-error">
                            <div className="alert-content">
                                <div className="icon__wrapper">
                                    <span><AiOutlineExclamationCircle /></span>
                                </div>
                                <p>An error occured. Whether a server error or email already in use.</p>
                            </div>
                            <span className="close" onClick={()=>setShowErrorMsg(false)}><AiOutlineClose /></span>
                        </div>
                    ) : ''}

                    {/* Name */}
                    <div className="form-wrapper">
                        <label htmlFor="fullname">Name *</label>
                        <div className="form-group">
                            <input type="email" className="form-input" id="fullname" name="fullname" placeholder="Fullname" value={fullname} onChange={onChange} required />
                        </div>
                    </div>
                    {/* Email */}
                    <div className="form-wrapper">
                        <label htmlFor="email">Email Address *</label>
                        <div className="form-group">
                            <input type="text" className="form-input" id="email" name="email" placeholder="Email Address" value={email} onChange={onChange} required />
                        </div>
                    </div>
                    {/* Password */}
                    <div className="form-wrapper">
                        <label htmlFor="password">Password *</label>
                        <div className="form-group">
                            <input type={showPass ? "text" : "password"} className="form-input" id="password" name="password" placeholder="Password" value={password} onChange={onChange} required />
                            <i className="show-icon">{showPass ? <AiOutlineEyeInvisible onClick={()=>setShowPass((prevState) => !prevState)} /> : <AiOutlineEye onClick={()=>setShowPass((prevState) => !prevState)} />}</i>
                        </div>
                    </div>
                    <div className="s-btn-group">
                        <button className="submit-button" id="submit-button" type="submit"> Register</button>
                    </div>
                    <div className="sign-in-text">
                        <ul>
                            <li><Link to="/sign-in">Sign In Instead</Link></li>
                        </ul>
                    </div>
                </form>
            </div>
        </section>
    )
}
