import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
/* Components */
import Sidebar from '../../components/Nav/Sidebar';
import Spinner from '../../components/Privacy/Spinner';
import moment from "moment";
import $ from 'jquery';
import 'datatables.net';
import { toast } from 'react-toastify';
import config from '../../config';
import axios from 'axios';
import { v1 as uuidv4 } from "uuid";
/* Firebase */
import { db } from '../../firebase';
import { collection, getDocs, getDoc, orderBy, query, where, deleteDoc, doc, updateDoc } from 'firebase/firestore';
/* Icons */
import { BsListCheck } from "react-icons/bs";
/* Styles */
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function SubmittedEstimates() {
    const [defaultFormCategory, setDefaultFormCategory] = useState('All Categories');
    const [filterActions, setFilterActions] = useState('Bulk Actions');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const tableRef = useRef(null);
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const navigate = useNavigate();

    const changeCategory = (event) => {
        setDefaultFormCategory(event.target.value);
    }

    const changeFilterActions = (event) => {
        let actions = event.target.value;
        if(actions === "Select All"){
            $('.tbl-checkbox').prop('checked', true);
            let clientIDs = [];
            $('.tbl-checkbox:checked').each(function() {
                clientIDs.push({
                    clientID: $(this).val()
                });
            });
            setSelectedClients(clientIDs);
        }
        if(actions === "Bulk Actions" || actions === "Deselect All"){
            $('.tbl-checkbox').prop('checked', false);
            setSelectedClients([]);
        }
        setFilterActions(event.target.value);
    }

    const deleteAllEntries = async (clientID) =>{
        // Delete clients
        await deleteDoc(doc(db, "clients", clientID));
        // Delete clientsTotalComputation
        const clientsRef1 = collection(db, "clientsTotalComputation");
        const q1 = query(clientsRef1, where("clientID", "==", clientID));
        const querySnapshot1 = await getDocs(q1);
        if(querySnapshot1.docs.length > 0){
            querySnapshot1.forEach(async (_doc) => {
                await deleteDoc(doc(db, "clientsTotalComputation", _doc.id));
            });
        }
        
        // Delete clientEstimates
        const clientsRef2 = collection(db, "clientEstimates");
        const q2 = query(clientsRef2, where("clientID", "==", clientID));
        const querySnapshot2 = await getDocs(q2);
        if(querySnapshot2.docs.length > 0){
            querySnapshot2.forEach(async (_doc) => {
                await deleteDoc(doc(db, "clientEstimates", _doc.id));
            });
        }
        
        // Delete clientAdditionalFees
        const clientsRef3 = collection(db, "clientAdditionalFees");
        const q3 = query(clientsRef3, where("clientID", "==", clientID));
        const querySnapshot3 = await getDocs(q3);
        if(querySnapshot3.docs.length > 0){
            querySnapshot3.forEach(async (_doc) => {
                await deleteDoc(doc(db, "clientAdditionalFees", _doc.id));
            });
            $(`#rowData-${clientID}`).remove();
            toast.success("Selected entries successfully deleted.");
        }
    }

    async function downloadQuotePdf(clientName, quoteId) {
        try {
            const fileName = `${clientName} - Stripe Quote Estimate #${uuidv4()}.pdf`
            const response = await axios.get(`https://files.stripe.com/v1/quotes/${quoteId}/pdf`, {
                headers: {
                    'Content-Type': 'application/pdf',
                    'Authorization': `Bearer ${config.STRIPE_API_KEY}`,
                    'Content-disposition': 'attachment; filename=" + "'+fileName
                },
                responseType: 'arraybuffer'
            });

            const data = response.data;

            // create a download anchor tag
            var downloadLink      = document.createElement('a');
            downloadLink.target   = '_blank';
            downloadLink.download = fileName;

            // convert downloaded data to a Blob
            var blob = new Blob([data], { type: 'application/pdf' });

            // create an object URL from the Blob
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            // set object URL as the anchor's href
            downloadLink.href = downloadUrl;

            // append the anchor to document body
            document.body.append(downloadLink);

            // fire a click event on the anchor
            downloadLink.click();

            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
            
        } catch (error) {
          console.error(error);
        }
    }

    const applyActions = async () => {
        if(filterActions === "Select All"){
            $('.tbl-checkbox').prop('checked', true);
            let clientIDs = [];
            $('.tbl-checkbox:checked').each(function() {
                clientIDs.push({
                    clientID: $(this).val()
                });
            });
            setSelectedClients(clientIDs);
        }
        if(filterActions === "Deselect All"){
            $('.tbl-checkbox').prop('checked', false);
            setSelectedClients([]);
        }
        if(filterActions === "Delete"){
            if(selectedClients.length > 0){
                if(window.confirm('Are you sure you want to delete these entries?')){
                    toast.warning("Deleting entries. Please wait...");
                    selectedClients.forEach(async (item) => {
                        deleteAllEntries(item.clientID);
                    });
                }
            }else{
                toast.error("Please select entries to delete.");
            }
        }
        // Mark as Complete
        if(filterActions === "Mark as Complete"){
            if(selectedClients.length > 0){
                if(window.confirm('Are you sure you want to complete these entries?')){
                    selectedClients.forEach(async (item) => {
                        const myClientsRef = doc(db, "clients", item.clientID);
                        const docSnap = await getDoc(myClientsRef);
                        if(docSnap.exists){
                            const formData = {
                                ...docSnap.data(),
                                status: 'Completed',
                            };
                            await updateDoc(myClientsRef, formData);
                            $(`#status-change-${item.clientID}`).removeClass();
                            $(`#status-change-${item.clientID}`).html('Completed');
                            $(`#status-change-${item.clientID}`).addClass('tbl-status status-Completed');
                        }
                    });
                    toast.success("Selected entries marked as complete.");
                    $('.tbl-checkbox').prop('checked', false);
                }
            }else{
                toast.error("Please select entries to Mark as Complete.");
            }
        }
        if(filterActions === "Mark as Canceled"){
            if(selectedClients.length > 0){
                if(window.confirm('Are you sure you want to cancel these entries?')){
                    selectedClients.forEach(async (item) => {
                        const myClientsRef = doc(db, "clients", item.clientID);
                        const docSnap = await getDoc(myClientsRef);
                        if(docSnap.exists){
                            const formData = {
                                ...docSnap.data(),
                                status: 'Canceled',
                            };
                            await updateDoc(myClientsRef, formData);
                            $(`#status-change-${item.clientID}`).removeClass();
                            $(`#status-change-${item.clientID}`).html('Canceled');
                            $(`#status-change-${item.clientID}`).addClass('tbl-status status-Canceled');
                        }
                    });
                    toast.success("Selected entries marked as canceled.");
                    $('.tbl-checkbox').prop('checked', false);
                }
            }else{
                toast.error("Please select entries to Mark as Canceled.");
            }
        }

        if(filterActions === "Mark as Pending"){
            if(selectedClients.length > 0){
                if(window.confirm('Are you sure you want to make these entries as pending?')){
                    selectedClients.forEach(async (item) => {
                        const myClientsRef = doc(db, "clients", item.clientID);
                        const docSnap = await getDoc(myClientsRef);
                        if(docSnap.exists){
                            const formData = {
                                ...docSnap.data(),
                                status: 'Pending',
                            };
                            await updateDoc(myClientsRef, formData);
                            $(`#status-change-${item.clientID}`).removeClass();
                            $(`#status-change-${item.clientID}`).html('Pending');
                            $(`#status-change-${item.clientID}`).addClass('tbl-status status-Pending');
                        }
                    });
                    toast.success("Selected entries marked as pending.");
                    $('.tbl-checkbox').prop('checked', false);
                }
            }else{
                toast.error("Please select entries to Mark as Pending.");
            }
        }
    }

    // Define a function to search for objects by key and value
    const searchObjects = (arr, key, value) => {
        return arr.filter((obj) => {
            return obj[key] === value || (Array.isArray(obj[key]) && searchObjects(obj[key], key, value).length);
        });
    };

    useEffect(() => {
        const table = $(tableRef.current).DataTable({
        data,
            columns: [
                { data: 'dateSubmitted', render: (data, type, row) => {
                    return `<input class="tbl-checkbox" type="checkbox" data-id="${row.clientID}" value="${row.clientID}" id="checkbox-${row.clientID}" />`;
                }},
                { title: 'Date Submitted', data: 'dateSubmitted' },
                { title: 'Date of Expiry', data: 'dateExpire' },
                { title: 'Client Name', data: 'clientName' },
                { title: 'Contact Email', data: 'email' },
                { title: 'Status', render: (data, type, row) => {
                    return `<span class="tbl-status status-${row.status}" id="status-change-${row.clientID}">${row.status}</span>`;
                }},
                { title: 'Total Price', data: 'totalPrice' },
                { title: 'Actions', render: (data, type, row) => {
                    return `
                        <div class="dropdown">
                            <button class="dropbtn">Options</button>
                            <div class="dropdown-content">
                                <a href="javascript:;" class="tbl-btn-items view-btn" data-id="${row.clientID}">View</a>
                                <a href="javascript:;" class="tbl-btn-items tbl-action-btn pending-btn" data-id="${row.clientID}" data-status="Pending">Pending</a>
                                <a href="javascript:;" class="tbl-btn-items tbl-action-btn cancel-btn" data-id="${row.clientID}" data-status="Cancelled">Cancel</a>
                                <a href="javascript:;" class="tbl-btn-items tbl-action-btn complete-btn" data-id="${row.clientID}" data-status="Completed">Complete</a>
                                <a href="javascript:;" class="tbl-btn-items tbl-action-btn download-quote-btn" data-id="${row.stripeQuoteID}" data-client="${row.fullName}">Download Quote</a>
                                <a href="javascript:;" class="tbl-btn-items tbl-action-btn delete-btn" data-id="${row.clientID}" data-status="Delete">Delete</a>
                            </div>
                        </div>
                    `;
                }}
            ],
            "fnCreatedRow": function( nRow, aData, iDataIndex ) {
                $(nRow).attr('id', `rowData-${aData.clientID}`);
            }
        });
        // View Estimates
        $('.tbl-btn-items.view-btn').on('click', (e)=>{
            navigate('/view-estimates/'+$(e.target).data('id'));
        });

        // Change Estimate Status
        $('.tbl-action-btn.delete-btn').on('click', async (e)=>{
            if(window.confirm('Are you sure you want to delete this entry?')){
                toast.warning("Deleting please wait...");
                let deleteID = $(e.target).data('id');
                deleteAllEntries(deleteID);
            }
        });

        // Change Estimate Status
        $('.tbl-action-btn.cancel-btn').on('click', async (e)=>{
            if(window.confirm('Are you sure you want to cancel this entry?')){
                toast.warning("Canceling, please wait...");
                let clientID = $(e.target).data('id');
                const myClientsRef = doc(db, "clients", clientID);
                const docSnap = await getDoc(myClientsRef);
                if(docSnap.exists){
                    const formData = {
                        ...docSnap.data(),
                        status: 'Canceled',
                    };
                    await updateDoc(myClientsRef, formData);
                    $(`#status-change-${clientID}`).removeClass();
                    $(`#status-change-${clientID}`).html('Canceled');
                    $(`#status-change-${clientID}`).addClass('tbl-status status-Canceled');
                }
                toast.success("Mark as canceled successfully");
            }
        });

        // Change Estimate Status
        $('.tbl-action-btn.complete-btn').on('click', async (e)=>{
            if(window.confirm('Are you sure you want to complete this entry?')){
                toast.warning("Completing, please wait...");
                let clientID = $(e.target).data('id');
                const myClientsRef = doc(db, "clients", clientID);
                const docSnap = await getDoc(myClientsRef);
                if(docSnap.exists){
                    const formData = {
                        ...docSnap.data(),
                        status: 'Completed',
                    };
                    await updateDoc(myClientsRef, formData);
                    $(`#status-change-${clientID}`).removeClass();
                    $(`#status-change-${clientID}`).html('Completed');
                    $(`#status-change-${clientID}`).addClass('tbl-status status-Completed');
                }
                toast.success("Mark as completed successfully");
            }
        });
        
        // Change Estimate Status
        $('.tbl-action-btn.pending-btn').on('click', async (e)=>{
            if(window.confirm('Are you sure you want to make this entry pending?')){
                toast.warning("Changing, please wait...");
                let clientID = $(e.target).data('id');
                const myClientsRef = doc(db, "clients", clientID);
                const docSnap = await getDoc(myClientsRef);
                if(docSnap.exists){
                    const formData = {
                        ...docSnap.data(),
                        status: 'Pending',
                    };
                    await updateDoc(myClientsRef, formData);
                    $(`#status-change-${clientID}`).removeClass();
                    $(`#status-change-${clientID}`).html('Pending');
                    $(`#status-change-${clientID}`).addClass('tbl-status status-Pending');
                }
                toast.success("Mark as pending successfully");
            }
        });

        // Download Quote
        $('.tbl-action-btn.download-quote-btn').on('click', async (e)=>{
            if(window.confirm('Do you want to download the quote from Stripe?')){
                toast.warning("Downloading quotes from stripe. Please wait...");
                let quoteID = $(e.target).data('id');
                let clientName = $(e.target).data('client');
                downloadQuotePdf(clientName, quoteID);
            }
        });

        // Check the box
        $('.tbl-checkbox').on('click', (e)=>{
            let clientIDs = [];
            $('.tbl-checkbox:checked').each(function() {
                clientIDs.push({
                    clientID: $(this).val()
                });
            });
            setSelectedClients(clientIDs);
        });

        return () => {
            table.destroy();
        };
    }, [data]);
    
    useEffect(()=>{
        setLoading(true);
        async function fetchClientsTotalComputation(){
            //  Fetch All Clients
            const clientsRef = collection(db, "clients");
            const q2 = (defaultFormCategory === "All Categories") ? query(clientsRef, orderBy("timestamp", "desc")) : query(clientsRef, where("formCategory", "==", defaultFormCategory), orderBy("timestamp", "desc"));
            const querySnapshot2 = await getDocs(q2);
            let clientsData = [];
            if(querySnapshot2.docs.length > 0){
                querySnapshot2.forEach((_doc2) => {
                    return clientsData.push({
                        ..._doc2.data(),
                        clientID: _doc2.id,
                    });
                });
            }
            setClients(clientsData);

            // Fetch Client's Total Computation
            const clientsTotalComputationRef = collection(db, "clientsTotalComputation");
            const q = (defaultFormCategory === "All Categories") ? query(clientsTotalComputationRef, orderBy("timestamp", "desc")) : query(clientsTotalComputationRef, where("formCategory", "==", defaultFormCategory), orderBy("timestamp", "desc"));
            const querySnapshot = await getDocs(q);
            let clientsTotalComputationData = [];
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((_doc) => {
                    const results = searchObjects(clientsData, "clientID", _doc.data().clientID);
                    const _dateSubmitted = _doc.data().timestamp;
                    const dateSubmitted = moment(_dateSubmitted.toDate()).format('MM/DD/yy');
                    const _dateExpire = _doc.data().expiresOn;
                    const dateExpire = moment(_dateExpire.toDate()).format('MM/DD/yy');
                    return clientsTotalComputationData.push({
                        ..._doc.data(),
                        dateSubmitted: dateSubmitted,
                        dateExpire: dateExpire,
                        clientName: (results[0]) ? results[0].fullName : '',
                        totalPrice: `$ ${Number(_doc.data().totalEstimatePrice).toFixed(2)}`,
                        ...results[0]
                    });
                });
            }
            setData(clientsTotalComputationData);
            setLoading(false);
        }
        fetchClientsTotalComputation();
    }, [defaultFormCategory]);


    /* Loading Screen */
    if(loading){
        return <Spinner />;
    }

    return (
        <>
            {/* Sidebar */}
            <Sidebar /> 

            {/* Section Content */}
            <section className="home_content">
                {/* Section Header */}
                <div className="dashboard-header-section">
                    <h4 className="page-title">Submitted Estimates</h4>
                </div>                
                <div className="datatable-wrapper">
                    <div className="table-filter-options">
                        <div className="filter-group">
                            <select name="defaultFormCategory" id="defaultFormCategory" className="search-input-field" value={defaultFormCategory} onChange={changeCategory}>
                                <option value="All Categories">All Categories</option>
                                <option value="Individual Estimates">Individual Estimates</option>
                                <option value="Business Estimates">Business Estimates</option>
                                <option value="Bookkeeping Estimates">Bookkeeping Estimates</option>
                            </select>
                        </div>
                        <div className="filter-group">
                            <select name="filterActions" id="filterActions" className="search-input-field" value={filterActions} onChange={changeFilterActions}>
                                <option value="Bulk Actions">Bulk Actions</option>
                                <option value="Select All">Select All</option>
                                <option value="Deselect All">Deselect All</option>
                                <option value="Mark as Complete">Mark as Complete</option>
                                <option value="Mark as Pending">Mark as Pending</option>
                                <option value="Mark as Canceled">Mark as Canceled</option>
                                <option value="Delete">Delete</option>
                            </select>
                        </div>
                        <div className="filter-group-btn">
                            <button className="filter-button" type="button" onClick={applyActions}>Apply</button>
                        </div>
                    </div>
                    <table className="form-table" ref={tableRef}>
                        <thead>
                            <tr>
                                <th><BsListCheck /></th>
                                <th>Date Submitted</th>
                                <th>Date of Expiry</th>
                                <th>Client Name</th>
                                <th>Contact Email</th>
                                <th>Status</th>
                                <th>Total Price</th>
                                <th className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody />
                    </table>
                </div>
            </section>
        </>
    )
}
