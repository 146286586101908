import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import config from '../../config';
import Stripe from 'stripe';
import { v1 as uuidv4 } from "uuid";
/* Firebase */
import { db } from '../../firebase';
import { collection, getDocs, orderBy, query, where, serverTimestamp, limit, Timestamp, addDoc } from 'firebase/firestore';
/* Icons */
import { FaSpinner } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import Spinner from '../../components/Privacy/Spinner';
import helpImg from "../../assets/img/help.svg";
import compLogo from "../../assets/img/company-logo.png";
/* Styles */
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function SubmitEstimates() {
    /* GET EXPIRY DATE */
    const _currentDate = new Date();
    const _futureDate = new Date(_currentDate);
    _futureDate.setDate(_currentDate.getDate() + 15);
    const expiresDate = Timestamp.fromDate(_futureDate);
    /* CLOSE GET EXPIRY DATE */

    const stripe = new Stripe(config.STRIPE_API_KEY);
    const [defaultFormCategory, setDefaultFormCategory] = useState('Individual Estimates');
    const [loading, setLoading] = useState(true);
    const [clientAddFees, setClientAddFees] = useState([]);
    const [estimationList, setEstimationList] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    
    const [clientTotalComputation, setClientTotalComputation] = useState({
        estimatedPrice: 0,
        formCategory: defaultFormCategory,
        promoCode: '',
        promoCodeType: '',
        promoCodeDiscount: 0,
        promoCodeDescription: '',
        stripePromoID: '',
        subTotal: 0,
        totalEstimatePrice: 0,
        totalEstimates: 0,
        timestamp: serverTimestamp(),
        expiresOn: expiresDate,
    });
    const [clients, setClients] = useState({
        email: '', 
        fullName: '',
        phoneNumber: '',
        howDidYouFindUs: '',
        agreeTheTerms: false,
        status: "Pending",
        formCategory: defaultFormCategory,
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressPostalCode: '',
        addressCountry: '',
        stripeQuoteID: '',
        timestamp: serverTimestamp()
    });

    /* PROMO CODES FUNCTION */
    const checkPromoCodes = async (event, index) => {
        let userPromoCode = event.target.value;
        const _dateToday = new Date();
        const today = Timestamp.fromDate(_dateToday);
        if(userPromoCode){
            const promoCodesRef = collection(db, "promoCodes");
            const q = query(
                promoCodesRef,
                where("promoCode", "==", userPromoCode),
                where('promoDateEnd', '>=', today),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            let promoCodesData = [];
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((doc) => {
                    let _discnt = Number(doc.data().discount);
                    let _promoDiscountPriceTxt = (doc.data().promoCodeType === "Fixed") ? `-$ ${_discnt.toFixed(2)}` : `-${_discnt}%`;
                    let _promoDiscountPrice = (doc.data().promoCodeType === "Fixed") ? _discnt : (clientTotalComputation.subTotal * _discnt) / 100;
                    return promoCodesData.push({
                        promoCodeDescription: `${doc.data().promoName} (${doc.data().promoCodeRemarks} ${_promoDiscountPriceTxt})`,
                        promoCodeDiscount: _promoDiscountPrice,
                        promoCodeType: doc.data().promoCodeType,
                        promoCode: doc.data().promoCode,
                        stripePromoID: doc.data().stripePromoID,
                    });
                });
            }

            let clientTotalComp = {
                formCategory: clientTotalComputation.formCategory,
                promoCode: ((promoCodesData.length > 0)) ? promoCodesData[0].promoCode : '',
                promoCodeDiscount: ((promoCodesData.length > 0)) ? promoCodesData[0].promoCodeDiscount : 0,
                promoCodeType: ((promoCodesData.length > 0)) ? promoCodesData[0].promoCodeType : '',
                promoCodeDescription: ((promoCodesData.length > 0)) ? promoCodesData[0].promoCodeDescription : '',
                stripePromoID: ((promoCodesData.length > 0)) ? promoCodesData[0].stripePromoID : '',
                subTotal: clientTotalComputation.subTotal,
                totalEstimatePrice: ((promoCodesData.length > 0)) ? clientTotalComputation.subTotal - promoCodesData[0].promoCodeDiscount : clientTotalComputation.subTotal,
                totalEstimates: clientTotalComputation.totalEstimates,
                timestamp: serverTimestamp(),
                expiresOn: clientTotalComputation.expiresOn,
            };

            setClientTotalComputation(clientTotalComp);
        }
    }
    /* CLOSE PROMO CODES FUNCTION */

    /* COMMON FUNCTIONS */
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    };
    /* CLOSE COMMON FUNCTIONS */

    /* Handle Input Change ============================================== */
    const changeCategory = (event) => {
        setDefaultFormCategory(event.target.value);
    }
    const handleRemarksChange = (event, index) => {
        const newItems = [...estimationList];
        newItems[index].remarks = event.target.value;
        setEstimationList(newItems);
    }
    const handleCheckboxChange = (event, index) => {
        const newItems = [...estimationList];
        var quantity = 0;
        if(event.target.checked){
            quantity = 1;
        }
        newItems[index].quantity = quantity;
        setEstimationList(newItems);
    }
    const handleQuantityChange = (event, index) => {
        const newItems = [...estimationList];
        const quantity = (event.target.value) ? parseInt(event.target.value) : '';
        newItems[index].quantity = quantity;
        setEstimationList(newItems);
    }
    /* Close Handle Input Change ======================================== */

    useEffect(()=>{
        setLoading(true);
        async function fetchAdditionalVariables(){
            const additionalVariablesRef = collection(db, "additionalVariables");
            const q = query(
                additionalVariablesRef,
                where("formCategory", "==", defaultFormCategory),
                orderBy("timestamp", "asc")
            );
            const querySnapshot = await getDocs(q);
            let additionalVariablesData = [];
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((doc) => {
                    const fieldValue = Number(doc.data().fieldValue);
                    const addFieldValueTxt = (doc.data().fieldType === "Fixed") ? `$ ${Number(doc.data().fieldValue).toFixed(2)}` : `${Number(doc.data().fieldValue).toFixed(2)}%`;
                    return additionalVariablesData.push({
                        clientID: '',
                        fieldDescription: `${doc.data().fieldTitle} (${doc.data().fieldRemarks})`,
                        addFieldType: doc.data().fieldType,
                        addFieldValue: fieldValue,
                        addFieldValueTxt: addFieldValueTxt
                    })
                });
                setClientAddFees(additionalVariablesData);
            }
            setLoading(false);
        }
        async function fetchEstimationList(){
            const estimationListRef = collection(db, "estimationList");
            const q = query(
                estimationListRef,
                where("formCategory", "==", defaultFormCategory),
                orderBy("order", "asc")
            );
            const querySnapshot = await getDocs(q);
            let estimationListData = [];
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((doc) => {
                    const price = Number(doc.data().formPrice);
                    const quantity = (doc.data().formTypePrice === 'Fixed') ? 1 : 0;
                    return estimationListData.push({
                        ...doc.data(),
                        formID: doc.id,
                        quantity: quantity,
                        decimalPrice: price,
                        subTotalPrice: quantity * price,
                    })
                });
                setEstimationList(estimationListData);
            }
            setLoading(false);
        }
        fetchAdditionalVariables();
        fetchEstimationList();
    }, [defaultFormCategory]);

    useEffect(()=>{
        function calculateTotalEstimates(){
            let subTotalPrice = estimationList.reduce((acc, item) => {return (item.quantity > 0) ? acc + item.quantity * item.decimalPrice : acc}, 0);
            let totalEstimates = subTotalPrice;
            clientAddFees.forEach((item, index) => {
                if(item.addFieldType === "Percentage"){
                    subTotalPrice += (totalEstimates * item.addFieldValue) / 100;
                }else{
                    subTotalPrice += item.addFieldValue;
                }
            });
            let subTotal = subTotalPrice;
            let totalPrice = subTotalPrice;

            if(clientTotalComputation.promoCode && clientTotalComputation.promoCodeDiscount && clientTotalComputation.promoCodeType){
                if(clientTotalComputation.promoCodeType === "Fixed"){
                    totalPrice += clientTotalComputation.promoCodeDiscount;
                }else{
                    totalPrice += (totalPrice * clientTotalComputation.promoCodeDiscount) / 100;
                }
            }

            let clientTotalComp = {
                formCategory: clientTotalComputation.formCategory,
                promoCode: clientTotalComputation.promoCode,
                promoCodeDiscount: clientTotalComputation.promoCodeDiscount,
                promoCodeType: clientTotalComputation.promoCodeType,
                promoCodeDescription: clientTotalComputation.promoCodeDescription,
                stripePromoID: clientTotalComputation.stripePromoID,
                subTotal: subTotal,
                totalEstimatePrice: totalPrice,
                totalEstimates: totalEstimates,
                timestamp: serverTimestamp(),
                expiresOn: clientTotalComputation.expiresOn
            };
            setClientTotalComputation(clientTotalComp);
        }
        calculateTotalEstimates();
    }, [estimationList]);

    const [errors, setErrors] = useState({});
    const submitClientEstimates = async (e) => {
        e.preventDefault();
        // Validate form data
        const validationErrors = {};

        // Full Name
        if (!clients.fullName.trim()) {
            validationErrors.fullName = 'Full Name is required';
        }
        // Address Line 1
        if (!clients.addressLine1.trim()) {
            validationErrors.addressLine1 = 'Address Line 1 is required';
        }
        // City
        if (!clients.addressCity.trim()) {
            validationErrors.addressCity = 'City is required';
        }
        // State
        if (!clients.addressState.trim()) {
            validationErrors.addressState = 'State is required';
        }
        // Country
        if (!clients.addressCountry.trim()) {
            validationErrors.addressCountry = 'Country is required';
        }
        // Postal Code
        if (!clients.addressPostalCode.trim()) {
            validationErrors.addressPostalCode = 'Postal Code is required';
        } 
        // How did you find US
        if (!clients.howDidYouFindUs.trim()) {
            validationErrors.howDidYouFindUs = 'Please tell us how did you find us.';
        }
        // Email
        if (!clients.email.trim()) {
            validationErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(clients.email)) {
            validationErrors.email = 'Invalid email format';
        }
        // Phone Number
        if (!clients.phoneNumber.trim()) {
            validationErrors.phoneNumber = 'Phone number is required';
        } else if (!/^\d{10}$/.test(clients.phoneNumber)) {
            validationErrors.phoneNumber = 'Invalid phone number format (must be 10 digits)';
        }
        // Agree The Terms
        if (!clients.agreeTheTerms) {
            validationErrors.agreeTheTerms = 'Please check the box if you agree.';
        }

        // Set errors
        setErrors(validationErrors);

        // If there are no errors, submit the form
        if (Object.keys(validationErrors).length === 0) {
            setLoadingButton(true);

            /* Create a new Quotes to Stripe  ================================================================= */
            try {
                // Create Customers
                const customer = await stripe.customers.create({
                    name: clients.fullName,
                    email: clients.email,
                    phone: clients.phoneNumber,
                    address: {
                        line1: clients.addressLine1,
                        line2: clients.addressLine2,
                        city: clients.addressCity,
                        state: clients.addressState,
                        postal_code: clients.addressPostalCode,
                        country: clients.addressCountry,
                    },
                });
                // Create Quote
                if(customer){
                    try {
                        // Generate Line Items
                        const lineItems = [];
                        estimationList.map((field, index) => {
                            if(estimationList[index].quantity > 0){
                                return lineItems.push({
                                    price: estimationList[index].stripePriceID,
                                    quantity: estimationList[index].quantity,
                                });
                            }
                        });
                        const expiresAt = Math.floor((Date.now() / 1000) + (15 * 24 * 60 * 60)); // 15 days from now
                        const promoCodeData = clientTotalComputation.stripePromoID ? [{ coupon: clientTotalComputation.stripePromoID }] : [];
                        const quoteData = await stripe.quotes.create({
                            customer: customer.id,
                            header: `${defaultFormCategory} - Tax Quote Calculations`,
                            expires_at: expiresAt,
                            line_items: lineItems,
                            discounts: promoCodeData,
                        });
                        console.log(quoteData);
                        // Finalize Quote and Get the PDF
                        await stripe.quotes.finalizeQuote(quoteData.id);

                        // Submit data to firebase
                        const myClientsCollection = collection(db, "clients");
                        const clientsCopy = {
                            ...clients,
                            formCategory: defaultFormCategory,
                            stripeQuoteID: quoteData.id,
                        }
                        const _getClientsData = await addDoc(myClientsCollection, clientsCopy);
                        if(_getClientsData && _getClientsData.id){
                            /* Submit Data to ZAP ============================================================================ */
                            try {
                                const ZAP_API_ENDPOINT = config.ZAP_URL;
                                // Submission Date
                                const currentDate = moment().format('MMM D, YYYY');
                                const _quoteID = _getClientsData.id;
                                // Expires On
                                const _expiresDate = Timestamp.fromDate(_futureDate);
                                const dateExpire = moment(_expiresDate.toDate()).format('MMM D, YYYY');

                                // Estimation final lists
                                // Insert data to Client Estimates
                                var estimationListHTML = '';
                                // estimationListHTML
                                estimationList.map((field, index) => {
                                    if(estimationList[index].quantity > 0){
                                        const amtPrice = `$${Number(estimationList[index].decimalPrice).toFixed(2) }`
                                        estimationListHTML += `
                                            <tr>
                                                <td style="font-size:16px;line-height:1;font-family:Calibri,sans-serif,'Motiva Sans';text-align:left;padding-top:15px;color:#000;border-bottom: 1px solid #cfc6c6;padding-bottom:15px;">${estimationList[index].formType} - ${estimationList[index].formDescription}</td>
                                                <td style="font-size:16px;line-height:1;font-family:Calibri,sans-serif,'Motiva Sans';text-align:right;padding-top:15px;color:#000;border-bottom: 1px solid #cfc6c6;padding-bottom:15px;">${estimationList[index].quantity}</td>
                                                <td style="font-size:16px;line-height:1;font-family:Calibri,sans-serif,'Motiva Sans';text-align:right;padding-top:15px;color:#000;border-bottom: 1px solid #cfc6c6;padding-bottom:15px;">${amtPrice}</td>
                                            </tr>
                                        `;
                                    }
                                });
                                // addFeesHTML
                                var addFeesHTML = '';
                                clientAddFees.map((field, index) => {
                                    const addFieldValTxt = (clientAddFees[index].addFieldType === "Fixed") ? clientAddFees[index].addFieldValueTxt : `$${Number((clientTotalComputation.totalEstimates * clientAddFees[index].addFieldValue) / 100).toFixed(2)} (${clientAddFees[index].addFieldValueTxt})`;
                                    addFeesHTML += `
                                        <tr>
                                            <td style="font-size:16px;line-height:1;font-family:Calibri,sans-serif,'Motiva Sans';text-align:left;padding-top:15px;color:#000;border-bottom: 1px solid #fff;padding-bottom:15px;"></td>
                                            <td style="font-size:16px;line-height:1;font-family:Calibri,sans-serif,'Motiva Sans';text-align:left;padding-top:15px;color:#000;border-bottom: 1px solid #fff;padding-bottom:15px;"></td>
                                            <td style="font-size:16px;line-height:1;font-family:Calibri,sans-serif,'Motiva Sans';text-align:left;padding-top:15px;color:#000;border-bottom: 1px solid #cfc6c6;padding-bottom:15px;">${clientAddFees[index].fieldDescription}</td>
                                            <td style="font-size:16px;line-height:1;font-family:Calibri,sans-serif,'Motiva Sans';text-align:right;padding-top:15px;color:#000;border-bottom: 1px solid #cfc6c6;padding-bottom:15px;">${addFieldValTxt}</td>
                                        </tr>
                                    `;
                                });

                                var params = {
                                    quoteNumber: `QT-${_quoteID.toUpperCase()}-1`,
                                    clientsData: clients,
                                    dateOfSubmission: currentDate,
                                    dateOfExpiry: dateExpire,
                                    formCategory: defaultFormCategory,
                                    totalEstimates: Number(clientTotalComputation.totalEstimates).toFixed(2),
                                    subTotal: Number(clientTotalComputation.subTotal).toFixed(2),
                                    promoCodeDescription: clientTotalComputation.promoCodeDescription,
                                    promoCodeDiscount: Number(clientTotalComputation.promoCodeDiscount).toFixed(2),
                                    totalEstimatePrice: Number(clientTotalComputation.totalEstimatePrice).toFixed(2),
                                    // Estimation List
                                    addFeesHTML: addFeesHTML,
                                    estimationListHTML: estimationListHTML,
                                };

                                const axiosInstance = axios.create({
                                    headers: {
                                        'Content-Type': 'application/json', // Set the Content-Type header
                                        Accept: 'application/json',
                                    },
                                });
                                const response = await axiosInstance.get(
                                    ZAP_API_ENDPOINT,
                                    { params },
                                );
                        
                                console.log('ZAP success', response);
                            } catch (error) {
                                console.log('ZAP ERROR', error);
                            }
                            /* Close Submit Data to ZAP ====================================================================== */

                            // Insert data to Clients Total Computation
                            const clientTotalComputationCopy = {
                                ...clientTotalComputation,
                                clientID: _getClientsData.id
                            };
                            const myClientsTotalComputationCollection = collection(db, "clientsTotalComputation");
                            await addDoc(myClientsTotalComputationCollection, clientTotalComputationCopy);

                            // Insert data to Client Estimates
                            estimationList.map(async (field, index) => {
                                if(estimationList[index].quantity > 0){
                                    const formData = {
                                        ...estimationList[index],
                                        clientID: _getClientsData.id,
                                        timestamp: serverTimestamp(),
                                    };
                                    const myClientEstimatesCollection = collection(db, "clientEstimates");
                                    await addDoc(myClientEstimatesCollection, formData);
                                }
                            });

                            // Insert data to Client Additional Fields
                            clientAddFees.map(async (field, index) => {
                                const formData = {
                                    ...clientAddFees[index],
                                    clientID: _getClientsData.id,
                                    timestamp: serverTimestamp(),
                                };
                                const myclientAddFeesCollection = collection(db, "clientAdditionalFees");
                                await addDoc(myclientAddFeesCollection, formData);
                            });

                            
                            toast.success("Successfully submitted!");
                            navigate("/thank-you-page");
                        }else{
                            toast.error("Oops. We got some problem while submitting your estimates. Please refresh your page and try again.");
                            setLoadingButton(false);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            } catch (error) {
                console.error(error);
            }
            /* Close Create a new Quotes to Stripe  =========================================================== */

        }
        setLoadingButton(false);
    };

    /* Loading Screen */
    if(loading){
        return <Spinner />;
    }

    return (
        <>
            {/* Section Content */}
            <section className="main_content">
                <div className="main-content-wrapper">
                    {/* Estimates Table */}
                    <div className="dashboard-header-section">
                        <h4 className="page-title">Please Select Quote Type</h4>
                    </div>
                    <div className="search-estimates-wrapper w-100">
                        <div className="search-est-items w-100">
                            <select name="defaultFormCategory" className="search-input-field w-100" id="defaultFormCategory" value={defaultFormCategory} onChange={changeCategory}>
                                <option value="Individual Estimates">Individual Estimates</option>
                                <option value="Business Estimates">Business Estimates</option>
                                <option value="Bookkeeping Estimates">Bookkeeping Estimates</option>
                            </select>
                        </div>
                    </div>
                    <div className="section-table">
                        <form onSubmit={submitClientEstimates}>
                            <div className="section-table">
                                <table className="form-table w-100" id="estimates-table">
                                    <thead>
                                        <tr>
                                            <th>Form Type</th>
                                            <th>Form Description</th>
                                            <th>Type</th>
                                            <th className="text-center">Price</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {estimationList.map((field, index) => {
                                            let inputField;
                                            if (field.formTypePrice === 'Fixed') {
                                                inputField = (<span><b>${field.decimalPrice}</b></span>);
                                            } else if (field.formTypePrice === 'Checkbox') {
                                                inputField = (<input type="checkbox" className="table-input" name="remarks" value={field.quantity} onChange={(event) => handleCheckboxChange(event, index)} />);
                                            } else {
                                                inputField = (<input type="number" className="table-input text-center" name="formPrice" placeholder="QTY" value={field.quantity} min="0" pattern="[0-9]*" onChange={(event) => handleQuantityChange(event, index)} />);
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td className="tbl-long-field">
                                                        <label className="field-label">Form Type:</label>
                                                        <span>{field.formType}</span>
                                                    </td>
                                                    <td className="tbl-long-field">
                                                        <label className="field-label">Form Description:</label>
                                                        <span>{field.formDescription}</span></td>
                                                    <td className="tbl-qty-field">
                                                        <label className="field-label">Type:</label>
                                                        {inputField}
                                                    </td>
                                                    <td className="tbl-price-field text-center">
                                                        <label className="field-label">Price:</label>
                                                        <span><b>${field.decimalPrice}</b></span>
                                                    </td>
                                                    <td className="tbl-long-field">
                                                        <label className="field-label">Remarks:</label>
                                                        <div className="form-input-with-close">
                                                            <input type="text" className="table-input" name="remarks" placeholder={field.remarks || '(Optional)'} value={field.remarks} onChange={(event) => handleRemarksChange(event, index)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <table className="form-table total-calculation-table partial-computation-table">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th className="text-right">Calculation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tbl-form-name-field"><span>Total Estimate</span></td>
                                            <td className="tbl-long-field text-right"><span><b>$ {Number(clientTotalComputation.totalEstimates).toFixed(2)}</b></span></td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-long-field" colSpan={2}><button className="submit-button" type="button" onClick={scrollToBottom}> Submit Estimate</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* Additional Variables Table */}
                            <div className="dashboard-header-section mt-50">
                                <h4 className="page-title">Contact Details & Total Computation</h4>
                            </div>
                            <div className="section-table">
                                <div className="form-group-wrapper">
                                    {/* Full Name */}
                                    <div className="row-container">
                                        <div className="col-12">
                                            <div className="form-grouped">
                                                <label htmlFor="fullName">Full Name <span className="reqired">*required</span></label>
                                                <input type="text" className="form-control" name="fullName" id="fullName" value={clients.fullName} placeholder="Full Name" onChange={(event) => {
                                                    setClients({ ...clients, fullName: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.fullName && event.target.value.trim()) {
                                                        setErrors({ ...errors, fullName: '' });
                                                    }
                                                }} />
                                                {errors.fullName && <p className="form-err-msg">{errors.fullName}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Email */}
                                    <div className="row-container">
                                        <div className="col-12">
                                            <div className="form-grouped">
                                                <label htmlFor="email">Email <span className="reqired">*required</span></label>
                                                <input type="email" className="form-control" name="email" id="email" value={clients.email} placeholder="Email" onChange={(event) => {
                                                    setClients({ ...clients, email: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.email && event.target.value.trim() && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value)) {
                                                        setErrors({ ...errors, email: '' });
                                                    }
                                                }} />
                                                {errors.email && <p className="form-err-msg">{errors.email}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Phone Number */}
                                    <div className="row-container">
                                        <div className="col-12">
                                            <div className="form-grouped">
                                                <label htmlFor="phoneNumber">Phone Number <span className="reqired">*required</span></label>
                                                <input type="text" className="form-control" name="phoneNumber" id="phoneNumber" value={clients.phoneNumber} placeholder="Phone Number" onChange={(event) => {
                                                    setClients({ ...clients, phoneNumber: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.phoneNumber && event.target.value.trim() && /^\d{10}$/.test(event.target.value)) {
                                                        setErrors({ ...errors, phoneNumber: '' });
                                                    }
                                                }} />
                                                {errors.phoneNumber && <p className="form-err-msg">{errors.phoneNumber}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Address Line 1 and 2 */}
                                    <div className="row-container">
                                        <div className="col-6">
                                            <div className="form-grouped">
                                                <label htmlFor="addressLine1">Address Line 1 <span className="reqired">*required</span></label>
                                                <input type="text" className="form-control" name="addressLine1" id="addressLine1" value={clients.addressLine1} placeholder="Address Line 1" onChange={(event) => {
                                                    setClients({ ...clients, addressLine1: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.addressLine1 && event.target.value.trim()) {
                                                        setErrors({ ...errors, addressLine1: '' });
                                                    }
                                                }} />
                                                {errors.addressLine1 && <p className="form-err-msg">{errors.addressLine1}</p>}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-grouped">
                                                <label htmlFor="addressLine2">Address Line 2 </label>
                                                <input type="text" className="form-control" name="addressLine2" id="addressLine2" value={clients.addressLine2} placeholder="Address Line 2" onChange={(event) => {
                                                    setClients({ ...clients, addressLine2: event.target.value });
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* City and State */}
                                    <div className="row-container">
                                        <div className="col-6">
                                            <div className="form-grouped">
                                                <label htmlFor="addressCity">City <span className="reqired">*required</span></label>
                                                <input type="text" className="form-control" name="addressCity" id="addressCity" value={clients.addressCity} placeholder="City" onChange={(event) => {
                                                    setClients({ ...clients, addressCity: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.addressCity && event.target.value.trim()) {
                                                        setErrors({ ...errors, addressCity: '' });
                                                    }
                                                }} />
                                                {errors.addressCity && <p className="form-err-msg">{errors.addressCity}</p>}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-grouped">
                                                <label htmlFor="addressState">State <span className="reqired">*required</span></label>
                                                <input type="text" className="form-control" name="addressState" id="addressState" value={clients.addressState} placeholder="State" onChange={(event) => {
                                                    setClients({ ...clients, addressState: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.addressState && event.target.value.trim()) {// ok
                                                        setErrors({ ...errors, addressState: '' });
                                                    }
                                                }} />
                                                {errors.addressState && <p className="form-err-msg">{errors.addressState}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Postal Code and Country */}
                                    <div className="row-container">
                                        <div className="col-6">
                                            <div className="form-grouped">
                                                <label htmlFor="addressPostalCode">Postal Code <span className="reqired">*required</span></label>
                                                <input type="number" className="form-control" name="addressPostalCode" id="addressPostalCode" value={clients.addressPostalCode} placeholder="Postal Code" onChange={(event) => {
                                                    setClients({ ...clients, addressPostalCode: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.addressPostalCode && event.target.value.trim()) {
                                                        setErrors({ ...errors, addressPostalCode: '' });
                                                    }
                                                }} />
                                                {errors.addressPostalCode && <p className="form-err-msg">{errors.addressPostalCode}</p>}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-grouped">
                                                <label htmlFor="addressCountry">Country <span className="reqired">*required</span></label>
                                                <input type="text" className="form-control" name="addressCountry" id="addressCountry" value={clients.addressCountry} placeholder="Country" onChange={(event) => {
                                                    setClients({ ...clients, addressCountry: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.addressCountry && event.target.value.trim()) {// ok
                                                        setErrors({ ...errors, addressCountry: '' });
                                                    }
                                                }} />
                                                {errors.addressCountry && <p className="form-err-msg">{errors.addressCountry}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* How did you find us */}
                                    <div className="row-container">
                                        <div className="col-12">
                                            <div className="form-grouped">
                                                <label htmlFor="howDidYouFindUs">How did you find us? <span className="reqired">*required</span></label>
                                                <input type="text" className="form-control" name="howDidYouFindUs" id="howDidYouFindUs" value={clients.howDidYouFindUs} placeholder="How did you find us?" onChange={(event) => {
                                                    setClients({ ...clients, howDidYouFindUs: event.target.value });
                                                    // Clear error if the field is valid
                                                    if (errors.howDidYouFindUs && event.target.value.trim()) {
                                                        setErrors({ ...errors, howDidYouFindUs: '' });
                                                    }
                                                }} />
                                                {errors.howDidYouFindUs && <p className="form-err-msg">{errors.howDidYouFindUs}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* I agree */}
                                    <div className="row-container">
                                        <div className="col-12">
                                            <div className="form-grouped from-group-checkbox">
                                                <input type="checkbox" className="table-input" name="agreeTheTerms" onChange={(event) => {
                                                    setClients({ ...clients, agreeTheTerms: (event.target.checked) ? true : false });
                                                    // Clear error if the field is valid
                                                    if (errors.agreeTheTerms && event.target.checked) {
                                                        setErrors({ ...errors, agreeTheTerms: '' });
                                                    }
                                                }} />
                                                <label htmlFor="agreeTheTerms"><span className="reqired">I acknowledge and agree to the <a href="/" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</span></label>
                                            </div>
                                            {errors.agreeTheTerms && <p className="form-err-msg">{errors.agreeTheTerms}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* Final Total Estimation */}
                                <table className="form-table final-calculation-table">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th className="text-right">Calculation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tbl-long-field"><span>Estimate</span></td>
                                            <td className="tbl-long-field text-right"><span><b>$ {Number(clientTotalComputation.totalEstimates).toFixed(2)}</b></span></td>
                                        </tr>
                                        {clientAddFees.map((field, index) => (
                                            <tr key={index}>
                                                <td className="tbl-long-field"><span>{ field.fieldDescription }</span></td>
                                                <td className="tbl-long-field text-right"><span>{ 
                                                    (field.addFieldType === "Fixed") ? field.addFieldValueTxt
                                                    : `$ ${Number((clientTotalComputation.totalEstimates * field.addFieldValue) / 100).toFixed(2)} (${field.addFieldValueTxt})`
                                                }</span></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="tbl-long-field"><span><b>Sub Total</b></span></td>
                                            <td className="tbl-long-field text-right"><span><b>$ {Number(clientTotalComputation.subTotal).toFixed(2)}</b></span></td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-long-field">
                                                 <div className="form-input-with-desc">
                                                    <input type="text" className="table-input promo-code-field" name="enterPromoCode" placeholder="Enter Promo Code" onChange={(event) => checkPromoCodes(event)} />
                                                    <p>{ clientTotalComputation.promoCodeDescription }</p>
                                                </div>
                                            </td>
                                            <td className="tbl-long-field text-right"><span>—$ {Number(clientTotalComputation.promoCodeDiscount).toFixed(2)}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-long-field"><span><b>Total</b></span></td>
                                            <td className="tbl-long-field text-right"><span><b>$ {Number(clientTotalComputation.totalEstimatePrice).toFixed(2)}</b></span></td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-long-field" colSpan={2}>
                                                <p className="expires-notice">Note: Estimate <span>expires on or after 15 days</span> after submission</p>
                                                {loadingButton ? 
                                                    (<button className="submit-button w-100" type="submit"><FaSpinner className="spinner" disabled /> Submitting. Please wait... </button>) : 
                                                    (<button className="submit-button w-100" type="submit"> Submit Estimate</button>)
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="help-button">
                    <button className="float-help-btn" onClick={()=>setShowModal((prevState) => !prevState)}><FiHelpCircle /> <span>Help</span></button>
                </div>
            </section>
            <div className="custom-modal" style={(showModal) ? {display: `inherit`} : {display: `none`}}>
                <div className="custom-modal-container">
                    <div className="custom-modal-inner">
                        <div className="help-img">
                            <img src={helpImg} alt="Image Helping" />
                        </div>
                        <div className="instructions">
                            <div className="help-img">
                                <img src={compLogo} alt="Image Helping" />
                            </div>
                            <h2>Thanks for visiting Trust Pointe Financial Services Inc.</h2>
                            <p>You will find an Individual Estimate Calculator, a Business Estimate Calculator, as well as an Bookkeeping Estimate Calculator below. <br />
                                Please choose the form of estimate you intend to complete.
                            </p>
                            <ul>
                                <li><b>Step 1:</b> Select the desired services and quantity. Include any additional comments you wish to share with our team.</li>
                                <li><b>Step 2:</b> Involves submitting an estimate to secure your price.</li>
                                <li><b>Step 3:</b> Schedule a meeting to discuss services and make adjustments in order to obtain an accurate price estimate.</li>
                                <li><b>Step 4:</b> Establish the scope of work.</li>
                            </ul>
                            <p>Please note that adjustments will be made to assure the accuracy of your quote. It may increase or decrease based on the submitted documents received or the finalized scope of services. It is essential to schedule a meeting with us in order to plan.</p>
                            <button className="submit-button w-100 mt-20" type="button" onClick={()=>setShowModal((prevState) => !prevState)}> Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
