import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import Spinner from '../Privacy/Spinner';

export default function SignUpRoute() {
    const { checkingStatus, loggedIn } = useAuthStatus();
    if(checkingStatus){
        return (
            <Spinner />
        );
    }
    return loggedIn ? <Navigate to="/dashboard" /> : <Outlet />;
}
