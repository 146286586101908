import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
/* Components */
import Sidebar from '../../components/Nav/Sidebar';
import Spinner from '../../components/Privacy/Spinner';
import { Link } from 'react-router-dom';
import moment from "moment";
import config from '../../config';
import axios from 'axios';
import { v1 as uuidv4 } from "uuid";
/* Firebase */
import { db } from '../../firebase';
import { doc, getDoc, getDocs, collection, query, where, serverTimestamp } from 'firebase/firestore';
/* Icons */
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaSpinner } from 'react-icons/fa';
/* Styles */
import '../../assets/css/styles.css';
import '../../assets/css/responsive.css';

export default function ViewEstimates() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [client, setClient] = useState(null);
    const [clientEstimates, setClientEstimates] = useState(null);
    const [clientAdditionalFees, setClientAdditionalFees] = useState(null);
    const navigate = useNavigate();

    async function downloadQuotePdf() {
        const quoteId = client.stripeQuoteID;
        const clientName = client.fullName;
        try {
            setLoadingButton(true);
            const fileName = `${clientName} - Stripe Quote Estimate #${uuidv4()}.pdf`
            const response = await axios.get(`https://files.stripe.com/v1/quotes/${quoteId}/pdf`, {
                headers: {
                    'Content-Type': 'application/pdf',
                    'Authorization': `Bearer ${config.STRIPE_API_KEY}`,
                    'Content-disposition': 'attachment; filename=" + "'+fileName
                },
                responseType: 'arraybuffer'
            });

            const data = response.data;

            // create a download anchor tag
            var downloadLink      = document.createElement('a');
            downloadLink.target   = '_blank';
            downloadLink.download = fileName;

            // convert downloaded data to a Blob
            var blob = new Blob([data], { type: 'application/pdf' });

            // create an object URL from the Blob
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            // set object URL as the anchor's href
            downloadLink.href = downloadUrl;

            // append the anchor to document body
            document.body.append(downloadLink);

            // fire a click event on the anchor
            downloadLink.click();

            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
            setLoadingButton(false);
            
        } catch (error) {
          console.error(error);
        }
    }

    useEffect(()=>{
        async function fetchClient(){
            const docRef = doc(db, "clients", params.clientID);
            const clientSnap = await getDoc(docRef);
            if(clientSnap.exists()){
                const clientsRef = collection(db, "clientsTotalComputation");
                const q = query(clientsRef, where("clientID", "==", params.clientID));
                const querySnapshot = await getDocs(q);
                let clientsData = [];
                if(querySnapshot.docs.length > 0){
                    querySnapshot.forEach((_doc2) => {
                        const _dateSubmitted = _doc2.data().timestamp;
                        const dateSubmitted = moment(_dateSubmitted.toDate()).format('MM / DD / yy');
                        const _dateExpire = _doc2.data().expiresOn;
                        const dateExpire = moment(_dateExpire.toDate()).format('MM / DD / yy');
                        return clientsData.push({
                            ..._doc2.data(),
                            dateExpire: dateExpire,
                            dateSubmitted: dateSubmitted,
                            ...clientSnap.data()
                        });
                    });
                }
                setClient(clientsData[0]);
            }else{
                navigate("/submitted-estimates");
            }
            setLoading(false);
        }
        async function fetchClientEstimates(){
            const clientEstimatesRef = collection(db, "clientEstimates");
            const q = query(clientEstimatesRef, where("clientID", "==", params.clientID));
            const querySnapshot = await getDocs(q);
            let clientEstimatesData = [];
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((doc) => {
                    return clientEstimatesData.push({
                        ...doc.data(),
                    });
                });
            }
            setClientEstimates(clientEstimatesData);
        }
        async function fetchClientAdditionalFees(){
            const clientAdditionalFeesRef = collection(db, "clientAdditionalFees");
            const q = query(clientAdditionalFeesRef, where("clientID", "==", params.clientID));
            const querySnapshot = await getDocs(q);
            let clientAdditionalFeesData = [];
            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((doc) => {
                    return clientAdditionalFeesData.push({
                        ...doc.data(),
                    });
                });
            }
            setClientAdditionalFees(clientAdditionalFeesData);
        }
        fetchClient();
        fetchClientEstimates();
        fetchClientAdditionalFees();
    }, [params.clientID]);

    /* Loading Screen */
    if(loading){
        return <Spinner />;
    }

    return (
        <>
            {/* Sidebar */}
            <Sidebar /> 

            {/* Section Content */}
            <section className="home_content">
                {/* Section Back Section */}
                <div className="back-page-container">
                    <Link to="/submitted-estimates"><AiOutlineArrowLeft /> Return to Estimates List</Link>
                </div>

                {/* Section Header */}
                <div className="dashboard-header-section">
                    <h4 className="page-title">General Informatiion</h4>
                </div>
                <div className="section-table">
                    <table className="simple-table">
                        <tbody>
                            <tr>
                                <td className="tbl-form-name-field"><span>Full Name</span></td>
                                <td className="tbl-long-field"><span>{ client.fullName }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Email</span></td>
                                <td className="tbl-long-field"><span>{ client.email }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Contact Number</span></td>
                                <td className="tbl-long-field"><span>{ client.phoneNumber }</span></td>
                            </tr>
                            {/* Address Area */}
                            <tr>
                                <td className="tbl-form-name-field"><span>Address Line 1</span></td>
                                <td className="tbl-long-field"><span>{ client.addressLine1 }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Address Line 2</span></td>
                                <td className="tbl-long-field"><span>{ client.addressLine2 }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>City</span></td>
                                <td className="tbl-long-field"><span>{ client.addressCity }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>State</span></td>
                                <td className="tbl-long-field"><span>{ client.addressState }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Country</span></td>
                                <td className="tbl-long-field"><span>{ client.addressCountry }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Postal Code</span></td>
                                <td className="tbl-long-field"><span>{ client.addressPostalCode }</span></td>
                            </tr>
                            {/* Other Details */}
                            <tr>
                                <td className="tbl-form-name-field"><span>How did you find us?</span></td>
                                <td className="tbl-long-field"><span>{ client.howDidYouFindUs }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Estimate Category</span></td>
                                <td className="tbl-long-field"><span>{ client.formCategory }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Date Submitted</span></td>
                                <td className="tbl-long-field"><span>{ client.dateSubmitted }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Date of Expiry</span></td>
                                <td className="tbl-long-field"><span>{ client.dateExpire }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Status</span></td>
                                <td className="tbl-long-field"><span>{ client.status }</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Estimation Lists Table */}
                <div className="dashboard-header-section">
                    <h4 className="page-title">Estimates</h4>
                </div>
                <div className="section-table">
                    <table className="form-table">
                        <thead>
                            <tr>
                                <th>Form Type</th>
                                <th>Form Description</th>
                                <th>Quantity</th>
                                <th className="text-right">Price</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientEstimates.length ? (
                                clientEstimates.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                             <td className="tbl-form-field">
                                                <label className="field-label">Form Type:</label>
                                                <span>{ item.formType }</span>
                                            </td>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Form Description:</label>
                                                <span>{ item.formDescription }</span>
                                            </td>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Quantity:</label>
                                                <span>{ item.quantity }</span>
                                            </td>
                                            <td className="tbl-form-field text-right">
                                                <label className="field-label">Price:</label>
                                                <span>$ { Number(item.decimalPrice).toFixed(2) }</span>
                                            </td>
                                            <td className="tbl-form-field">
                                                <label className="field-label">Remarks:</label>
                                                <span>{ (item.remarks) ? item.remarks : '-' }</span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td className="tbl-form-field" colSpan={5}>
                                        <label className="field-label">Empty:</label>
                                        <span>No records found.</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <table className="form-table total-calculation-table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th className="text-right">Calculation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tbl-form-name-field"><span>Estimate</span></td>
                                <td className="tbl-long-field text-right"><span>$ { Number(client.totalEstimates).toFixed(2) }</span></td>
                            </tr>
                            <tr>
                                
                            </tr>
                            {/* Additional Processing Fees */}
                            {clientAdditionalFees.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="tbl-form-name-field"><span>{ item.fieldDescription }</span></td>
                                         <td className="tbl-long-field text-right"><span>{ item.addFieldValueTxt }</span></td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td className="tbl-form-name-field"><span><b>Sub Total</b></span></td>
                                <td className="tbl-long-field text-right"><span><b>$ { Number(client.subTotal).toFixed(2) }</b></span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span>Promo Code: { client.promoCode } { (client.promoCodeDescription) ? `(${client.promoCodeDescription})` : `` }</span></td>
                                <td className="tbl-long-field text-right"><span>- ${ Number(client.promoCodeDiscount).toFixed(2) }</span></td>
                            </tr>
                            <tr>
                                <td className="tbl-form-name-field"><span><b>Total</b></span></td>
                                <td className="tbl-long-field text-right"><span><b>$ { Number(client.totalEstimatePrice).toFixed(2) }</b></span></td>
                            </tr>
                            <tr>
                                <td className="tbl-long-field" colSpan={2}>
                                    {loadingButton ? 
                                        (<button className="submit-button w-100" type="submit"><FaSpinner className="spinner" disabled /> Downloading. Please wait... </button>) : 
                                        (<button className="submit-button w-100" type="submit" onClick={downloadQuotePdf}> Download Quote</button>)
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}
