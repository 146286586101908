// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyARp-JyZKj8eMCYRGgbt3weRbPn-HolFKs",
  authDomain: "trust-pointe-tax-calculator.firebaseapp.com",
  projectId: "trust-pointe-tax-calculator",
  storageBucket: "trust-pointe-tax-calculator.appspot.com",
  messagingSenderId: "257317029586",
  appId: "1:257317029586:web:3cb04bdd52627f5c23cf0b",
  measurementId: "G-484TMLTD6B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

export const db = getFirestore();