import React from 'react';
import mailIcon from "../../assets/img/mail.svg"
import { Link } from 'react-router-dom';

export default function ThankYou() {
    return (
        <>
            <section className="main-form-section thank-you">
                <div className="form-section-wrapper">
                    <div className="message-form">
                        <div className="message-form-left">
                            <div className="ty-icon">
                                <img src={mailIcon} alt="Mail Icon..." />
                            </div>
                            <div className="ty-msg">
                                <h1 className="page-title">Thank you for your submission</h1>
                                <h4>Thank you for submitting your estimates data to Trust Pointe Financials. Our team will now review your submission and get in touch with you as soon as possible. We appreciate your interest in our platform and look forward to the opportunity to work with you.</h4>
                                <Link className="submit-button" to="/submit-estimates">Submit Another Estimates</Link>
                            </div>
                        </div>
                        <div className="calendar-content"><iframe src="https://calendly.com/trustpointefinancials" title="Trust Pointe Book Appointment"></iframe></div>
                    </div>
                </div>
            </section>
        </>
    )
}
